/* 公共变量定义 */
$blk:#1c1b1a;
$base:#f18e00;
$gray:#7f7f7f;
::-webkit-scrollbar{width: 0;height:0;display: none;background: transparent;height: 6px;}
::-webkit-scrollbar-thumb{background:rgba(0,0,0,.3);border-radius: 10px;}
/* 公共mixin定义 */
@mixin font-family {font-family: Helvetica, "Microsoft YaHei","Heiti","Heiti SC", "DroidSans", "DroidSansFallback","Helvetica Neue",  "STHeiTi", Arial, sans-serif;}
@mixin border {border:1px solid #e8e8e8; }
@mixin text_point($width:100%) {width: $width; overflow:hidden; text-overflow:ellipsis; white-space: nowrap; }
@mixin fake_img($url,$w:100%,$h:100%){content:"";display:block;background:url($url) center no-repeat;position:absolute;top:0;background-size: $w $h}
@mixin bg_img($url,$w:100%,$h:100%){background:url($url) center no-repeat;background-size: $w $h;}

/* reset */
html{@include font-family;min-height:100%;min-width:1200px;-webkit-tap-highlight-color: transparent;}
body{margin:0;padding:0;background: #f7f7f7;color:$blk;-webkit-text-size-adjust:none;background-color: #fff;min-height:100%;}

ul,ol,li,form{margin:0;padding:0;-webkit-overflow-scrolling : touch;}
ul,ol{list-style:none;}

img{border:0;}
em,i,span{font-style: normal;}
h1,h2,h3,h4,div,li,p{margin:0;padding:0;font-weight:normal;-webkit-tap-highlight-color:transparent;-webkit-overflow-scrolling : touch;}
input, select, textarea{-webkit-tap-highlight-color: rgba(0,0,0,0);margin:0;padding:0;@include font-family; -webkit-appearance: none;
    &:focus{outline: none}
}

.clearfix:after{content:'';display:block;clear: both;}
.clearfix_o{overflow:hidden;}

a{color:$blk;text-decoration: none;}

table { border-collapse: collapse; border-spacing: 0; }
td, th { padding: 0; }

.zonybir{color:#fff;}
.btn{width:100px;text-align: center;padding: 8px 0;border-radius: 4px;background-color: #f7c976;font-size: 12px;color:#fff;}

.ellipsis{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

#floruit_container{box-sizing:border-box;min-width: 1280px;padding-left: 200px;margin:0 auto;min-height:100%;
	.left_container{position:absolute;top:0;left: 0;width:200px;min-height:100%;background-color: rgb(54,53,58);@include bg_img('../img/left_bg.png');z-index:10;
		.floruit_logo{height:80px;line-height: 80px;background-color: rgb(74,73,78);padding-bottom: 3px;@include bg_img('../img/logo.png',auto,auto);background-color: rgba(255,255,255,.1);}
		.floruit_search{width:180px;border:1px solid #fff;overflow: hidden;margin:20px auto;box-sizing: border-box;padding-left:40px;
		@include bg_img('../img/search.png',14px,14px);background-position: 13px center;
			input{height:30px;line-height: 30px;border:none;background-color: #fff;box-sizing: border-box;font-size: 14px;width:100%;background-color:rgb(54,53,58);color:#fff; }
		}
		.nav_title{color:#fff;font-size: 18px;text-align:left;padding-left: 50px;margin:10px 0;position:relative;font-weight:500;
			&:before,&:after{content:'';display:block;width:14px;height:14px;position:absolute;top:6px;}
			&:before{@include bg_img('../img/set.png',14px,14px);left:18px;}
			&:after{@include bg_img('../img/icon_down.png',10px,6px);right:15px;}
		}
		.left_nav{color:#fff;
			li{font-size: 14px;text-align: left;padding:17px 0;padding-left: 50px;border-left: 3px solid rgb(54,53,58);
				&.active{border-color:$base;background-color: rgba(0,0,0,.2);}
				a{font-size: 14px;color:#fff;}
			}
		}
	}


		.top_container{height:80px;line-height: 80px;padding-bottom: 3px;border-bottom: 1px solid #ddd;box-shadow: 0 0 3px rgba(0,0,0,.2);margin-bottom: 30px;box-sizing:border-box;z-index:9;
			.top_nav{width:740px;float: left;
				li{float: left;text-align: center;font-size: 14px;color: #333;padding:0 10px;margin-left: 30px;border-bottom: 3px solid #fff;
					&.active{border-color: $base;
						a{color:$base;}
					}
					a{display: block;}
					&:hover{
						a{color:$base;}
					}
				}
			}
			.right_admin{min-width:340px;float: right;height:80px;
				.admin_inf{position:relative;padding-right: 50px;float: left;width:200px;box-sizing:border-box;padding-top: 20px;
					p{line-height:20px;color:#666;
						.admin_name{font-size:16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
						.admin_rule{font-size:14px;}
					}
					div{position:absolute;right:0;width:50px;height:50px;top:15px;@include bg_img('../img/icon_down_b.png',10px,5px);cursor:pointer;
					background-position:right center;
						img{width:30px;height:30px;vertical-align:top;margin-top: 10px;}
					}
				}
				.admin_do_super{width:170px;float: left;height:30px;margin-top: 25px;border-left:3px solid #ddd;box-sizing:border-box;margin-left: 10px;
					div{width:20px;height:20px;float: left;margin-left: 30px;margin-top: 5px;@include bg_img('../img/home.png',20px,17px);cursor:pointer;
						&.home{}
						&.message{background-image: url("../img/message.png");}
						&.exit{background-image: url("../img/exit.png");}
					}
				}
			}
		}
		.do_att_info{padding:0 40px 30px 40px;margin-bottom: 10px;
			div{background-color: #f0efef;padding:15px 20px;box-sizing: border-box;
				h4{font-size: 18px;color:#666;margin: 5px 0 10px;padding-left: 28px;@include bg_img('../img/notic_inf.png',20px,20px);
				background-position:0 center;
				}
				p{font-size: 12px;color:#999;margin-top: 5px;
					span{color:#e95513;}
				}
			}
		}
		.wap_container{padding:0 40px 0 40px;position: relative;padding-left:650px;
			.wap_phone_modle{position: relative;float: left;width:352px;height:609px;padding-bottom: 50px;@include bg_img('../img/phones.png',335px,609px);background-position:0 0;
				.btn{position: absolute;left: 35px;bottom:0;}
				.view_btn{background-color: $base;}
				.save_btn{left: auto;right: 35px;}
			}
			iframe{width:560px;height:550px;position:absolute;top:10px;left:45px;border:none;z-index:600;}
			.wap_nav_controle{border-bottom: 2px solid #ddd;margin-bottom: 40px;height:35px;
				li{display: inline-block;float: left;margin-bottom: -2px;;margin-right: 25px;font-size: 14px;
					&:last-child{margin-right: 0;}
					a{display:block;;color:#666;height:35px;line-height: 30px;padding:0 10px;border-bottom: 2px solid #ddd;
						&.active{border-color: $base;color:$base;}
					}
				}
			}
		}

}

/* PC*/
#wap_cms_manage{
	.pcCmsContainer{margin-left:0;}
	.do_btn_group{padding-bottom:80px;
		.btn{float: left;margin-right: 48px;cursor:pointer;
			&:last-child{margin-right: 0;}
		}
	}
	.isAddBtn{background-color: #f7f7f7;cursor:pointer;
		&:after,&:before{content:'';display: block;width:60px;height: 8px;background-color: rgb(210,210,210);position: absolute;top:74px;left:52px;border-radius: 7px;z-index:10;}
		&:after{}
		&:before{transform: rotate(90deg);}
	}
	.withTitleInput{height:30px;margin-top: 30px;line-height:30px;padding:0 20px;
		label{font-size:14px;color:#333;margin-right: 10px;}
		.langInp{border:1px solid #ddd;border-radius:5px;background-color: #f0f0f0;font-size:12px;color:#666;width:375px;height:30px;padding:0 10px;box-sizing:border-box;}
		.typeSelec{width:120px;height:30px;line-height:30px;padding:0 20px;box-sizing:border-box;border:1px solid #ddd;
		margin-right: 20px;border-radius:5px;font-size:12px;background-color: #f0f0f0;color:#aaa;@include bg_img('../img/icon_down_g.png',10px,5px);
		background-position:98px center;
			option{background-color: #fefefe;font-size:12px;padding:0 20px;height:30px !important;line-height:30px;margin-top: 30px;
				&:nth-child(2n){background-color: #f0f0f0;}
			}
		}
		.smallInp{width:230px;}
	}
	.uploadImgContainer{position:relative;padding-left: 115px;min-height:100px;width:370px;
		
		.upimgbox{position:absolute;left:0;top:0;width:100px;height:100px;border-radius:5px;
			.upImgGuide{position:absolute;left:0;top:0;width:100px;height:100px;}
			&.isAddBtn{
				&:before,&:after{width:30px;height:3px;left:35px;top:48px;}
			}
			&.active{border:1px dashed #aaa;}
			.upFileBox{position:absolute;width:100px;height:100px;left:0;top:0;opacity:0;cursor:pointer;z-index:800;}
		}
		h5{font-size:14px;color:#333;margin:0;padding-bottom: 15px;font-weight:normal;}
		p{font-size:12px;color:#777;line-height:20px;}
	}


}

#wap_index{margin-left: 352px;
	.wap_slider_container{overflow:hidden;
		.img_item{width:164px;height:164px;margin-right: 42px;border:1px solid #a7a7a7;border-radius: 6px;position: relative;float: left;
		margin-bottom: 40px;padding:12px 32px;box-sizing: border-box;
		&:nth-child(3n){margin-right: 0;}
		&.smalImgItem{
			&:nth-child(3n){margin-right:42px;}
			&:nth-child(5n){margin-right:0;}
		}
			img{position: absolute;left: 0;top:0;width: 100%;height: 100%;opacity: .7;
			}
			div{height:20px;overflow: hidden;@include bg_img('../img/do_some.png',80px,15px);position:relative;z-index:9;
				span{display: block;width:17px;height: 17px;float: left;margin-left: 6px;cursor:pointer;}
			}
			&:last-child{margin-right: 0;}
		}
		&.more_width_list{
			.img_item{width:300px;padding:0 0;
				div{float: right;margin-right:20px;margin-top: 15px;}
			}
			.isAddBtn{
				&:after,&:before{left:120px;}
			}
		}
	}

}
#wap_todayHot{margin-left: 352px;
	.todayHotContainer{overflow:hidden;
		.hot_item{width:460px;height:200px;border:1px solid #e8e8e8;border-radius:5px;float: left;margin-right: 30px;margin-bottom: 40px;position:relative;
			&:nth-child(2n){margin-right: 0}
			h4{overflow:hidden;font-size:14px;color:#333;padding:10px 20px;border-bottom:1px solid #e8e8e8;font-weight:bold;
				div{width:76px;float: right;@include bg_img('../img/do_order.png')}
				i{display:block;width:12px;height:14px;margin-left: 17px;float: right;cursor:pointer;
					&:last-child{margin-left: 0;}
				}
			}
			&.isAddBtn{
				&:after,&:before{top:86px;left:200px;}
			}
		}
	}
}
#numSale{margin-left: 352px;
	.numSaleContainer{padding-bottom: 40px;
		.withTitleInput{margin-bottom:32px;padding:0;margin-top: 0;
			label{width:80px;display:inline-block;}
			.small_title{font-size:14px;color:#333;padding-left:28px;margin-right: 18px;}
			.langInp {width:285px;}
			.priceInp{
				width:100px;
			}
			&.setTimeContainer{position:relative;padding-left: 95px;
				label{position:absolute;left:0;top:0;}
				.setTimebox{width:100px;height:30px;line-height:30px;float: left;background-color: #f0f0f0;border:1px solid #ddd;font-size:12px;color:#333;padding:0 20px;padding-top: 3px;
				@include bg_img('../img/icon_down_up.png',9px,11px);background-position:120px center;
					&.leftDate{border-top-left-radius:5px;border-bottom-left-radius:5px;}
					&.rightTime{border-left:none;border-top-right-radius:5px;border-bottom-right-radius:5px;}
				}
			}
		}

	}
}
#todayTop{margin-left: 352px;
	.todayTopContainer{overflow:hidden;
		.top_item{float: left;margin-right: 40px;margin-bottom: 40px;
			&:last-child{margin-right: 0;}
			h4{font-size:16px;color:$base;}
			.withTitleInput{padding:0;
				label{width:75px;display:inline-block;}
				.langInp {width:300px;}
				.priceInp{width:105px;}
				span{font-size:14px;padding-left:32px;padding-right: 20px;}
			}
			.uploadImgContainer{margin-top: 40px;width:265px;

			}
		}
		&.isArea{
			.uploadImgContainer{padding-left: 195px;width:200px;
				.upimgbox{width:180px;
					.upFileBox{width:180px;}
					&.isAddBtn{
						&:after,&:before{left:75px;}
					}
				}
			}
		}
	}
}

//
.alertStyle{
  width: 460px;
  position: fixed;
  background: #fff;
  padding: 10px;
  border-radius: 2%;
  -webkit-box-shadow:0 0 10px #CCC;
  -moz-box-shadow:0 0 10px #CCC;
  box-shadow:0 0 10px #CCC;
  z-index: 900;
  left: 50%;
  top: 50%;
  margin-left: -230px;
  margin-top: -100px;
  display:none;
}
.saveSucces{margin-top:20px;width:200px;margin-left: -120px;padding: 20px;}
.alertStyle h1{ width: 100%;text-align:center;font-size: 20px;color: #666;padding: 15px 0px;}
.alertStyle h1 img{margin-right: 10px;}
.alertStyle p{color: #999;line-height: 28px;text-align: center;}
/* basic style */
.alertStyle .close{
    /* still bad on picking color */
    background: #000;
    color: #fff;
    /* make a round button */
    border-radius: 50%;
    /* center text */
    line-height: 20px;
    text-align: center;
    height: 20px;
    width: 20px;
    font-size: 18px;
    padding: 2px;
}
.saveSucces h1{font-size: 14px;color: #666;text-align: left; line-height: 28px; text-align:center;}
/* use cross as close button */
.alertStyle .close::before {
    content: "\2716";
}
/* place the button on top-right */
.alertStyle .close{
    top: -10px;
    right: -10px;
    position: absolute;
    cursor: pointer;
}
.alertStyle .uploadImgContainer {padding-left: 195px; width: 200px; position: relative;min-height: 100px;}

.alertStyle .isAddBtn {
 background-color: #f7f7f7;
 cursor: pointer; }
.alertStyle .uploadImgContainer .upimgbox.isAddBtn:before, .alertStyle  .uploadImgContainer .upimgbox.isAddBtn:after {
   width: 30px;
   height: 3px;
   left: 35px;
   top: 48px;
   }
.alertStyle .isAddBtn:after, .alertStyle .isAddBtn:before {
  content: '';
  display: block;
  width: 60px;
  height: 8px;
  background-color: #d2d2d2;
  position: absolute;
  top: 74px;
  left: 52px;
  border-radius: 7px; }
 .alertStyle .isAddBtn:before {
  -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
.alertStyle .uploadImgContainer h5 {
  font-size: 14px;
  color: #333;
  margin: 0;
  padding-bottom: 15px;
  font-weight: normal; }
.alertStyle .uploadImgContainer .upimgbox .upFileBox {
   position: absolute;
   width: 100px;
   height: 100px;
   left: 0;
   top: 0;
   opacity: 0;
   cursor: pointer;
   z-index: 800; }
.alertStyle .uploadImgContainer .upimgbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  border-radius: 5px; }
.alertStyle .uploadImgContainer .upimgbox.active {
   border: 1px dashed #aaa; }
.alertStyle .uploadImgContainer .upimgbox {width: 180px; }
.alertStyle .uploadImgContainer .upimgbox .upFileBox {width: 180px; }
.alertStyle .uploadImgContainer .upimgbox.isAddBtn:after, .alertStyle .uploadImgContainer .upimgbox.isAddBtn:before {left: 75px; }
.alertStyle .uploadImgContainer p {
  font-size: 12px;
  color: #777;
  line-height: 20px; }
.alertStyle .withTitleInput {
 height: 30px;
 margin-top: 30px;
 line-height: 30px;
 padding: 0 20px; }
 .alertStyle .withTitleInput label {
  font-size: 14px;
  color: #333;
  margin-right: 10px; }
 .alertStyle .withTitleInput .langInp {
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 12px;
  color: #666;
  width: 375px;
  height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  }
 .alertStyle .withTitleInput .typeSelec {
  width: 120px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #f0f0f0;
  color: #aaa;
  background: url("/img/icon_down_g.png") center no-repeat;
  background-size: 10px 5px;
  background-position: 98px center; }
.alertStyle .withTitleInput .typeSelec option {
   background-color: #fefefe;
   font-size: 12px;
   padding: 0 20px;
   height: 30px !important;
   line-height: 30px;
   margin-top: 30px; }
 .alertStyle .withTitleInput .typeSelec option:nth-child(2n) {
    background-color: #f0f0f0; }

.alertStyle .btn {
    width: 160px;
    text-align: center;
    padding: 8px 0;
    border-radius: 4px;
    background-color: #f7c976;
    font-size: 12px;
    color: #fff;
    margin: 30px;
    margin-left: 120px;
    cursor: pointer;
	box-sizing:border-box;
}
.view_btn {
    width: 160px;
    text-align: center;
    padding: 8px 0;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    margin: 30px;
    margin-left: 150px;
    cursor: pointer;
    background-color: #f18e00;
}
.saveSuccesBtn{width: 120px;text-align: center;padding: 8px 0;font-size: 12px;color: #fff;margin: 10px;margin-left: 38px;cursor: pointer;background-color: #f18e00;}
.to_buy_btnz{background-color: #f5483a;  display: inline-block;width: 60px;height:20px; line-height:20px;font-size: 12px;text-align: center; border-radius:6px;color: #fff;float: right;margin-top:5px;}
.to_buy_btnz_statu{background-color: #ccc;  display: inline-block;width: 60px;height:20px; line-height:20px;font-size: 12px;text-align: center; border-radius:6px;color: #fff;float: right;margin-top:5px;}
.clear_btn{width:100px;text-align: center;margin: 20px 0px;padding: 8px 0px;cursor: pointer;margin-left: 50px;border-radius: 4px;font-size: 12px;color: #fff;background-color: #f18e00;float: left;}

.alertStyle .withTitleInput .smallInp {width: 230px; }
.alertStyle .withTitleInput .smallInp.largeInpz{width:276px;}
.alertStyle .withTitleInput .smallInp.estSmallInp{width:100px;}
.alertStyle .withTitleInput .estSmallInpTitle{margin-left:40px;}
.alertStyle .withTitleInput span {
     font-size: 14px;
     padding-left: 32px;
     padding-right: 20px; }
.alertStyle .cuber{
      padding-left: 125px;
}
.goods_inf_detail {font-size: 12px;color: #777;margin-top: 0;line-height: 20px;height: 20px;}
.alertStyle .cuber .upimgbox .upFileBox {width: 100px; }
.alertStyle .cuber .upimgbox {width: 100px; }
.alertStyle .cuber .upimgbox.isAddBtn:after, .alertStyle .cuber .upimgbox.isAddBtn:before {left:35px; }
.mark{width:100%; height: auto; opacity:0.2; cursor: pointer;}
.type_item_moduleleft{width: 60%;float: left;}
.type_item_module h1{font-size:16px;color:#f18e00;line-height: 20px;height: 20px;text-align: center;}
.type_item_module p{line-height: 26px; height: 26px;}.type_item_module p a{font-size: 12px;}
.type_item_module span{
    width:0px;
    height: 0;
    border-top:5px solid transparent;
    display: block;
    float: left;
    border-left:10px solid #999;
    border-bottom: 5px solid transparent;
    margin-top: 10px;
    }
.pagelayout{width:100%;text-align: center;font-size: 14px;clear: both;margin: 15px;height: 40px;}
.pagelayout p {float: left;padding: 10px;background: #ccc;color: #333;border-radius: 50%;cursor: pointer;margin: 10px;}
.pagelayout p.active{color: #f18e00;}


// date piker
.date_picker{position: absolute;width: 252px;border-radius: 6px;background: #fff;box-shadow: 0 0 10px 0 rgba(0,0,0,.5);color: #333;font-family:"Microsoft YaHei", "微软雅黑", "STHeiTi", Arial, sans-serif,sans-serif;font-size: 12px;user-select: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;z-index:9999;}

.date_picker .pk_tit{height: 35px;border-bottom: 1px solid #e6e6e6;line-height: 35px;padding-left: 10px;}
.date_picker .pk_tit .pk_close{float: right;font-size: 20px;padding: 0 10px;cursor: pointer;color:#bbb}
.date_picker .pk_tit .pk_close:hover{color:#333}

.date_picker .change_date {height: 35px;border-bottom: 1px solid #e6e6e6;line-height: 35px;text-align: center}
.date_picker .change_date span{display: inline-block;}
.date_picker .change_date .pre_year,
.date_picker .change_date .pre_mon,
.date_picker .change_date .next_mon,
.date_picker .change_date .next_year{margin: 0 5px;width: 20px;font-size: 14px;cursor: pointer;color:#bbb}
.date_picker .change_date .pre_year:hover,
.date_picker .change_date .pre_mon:hover,
.date_picker .change_date .next_mon:hover,
.date_picker .change_date .next_year:hover{color:#333}
.date_picker .change_date .cur_mon {display: inline-block;width: 107px;text-align: center}

.date_picker .pk_main{border-bottom: 1px solid #e6e6e6;}
.date_picker .pk_main .main_tit {text-align: center;overflow: hidden;height: 28px;line-height: 28px;}
.date_picker .pk_main .main_tit span{float: left;width: 36px;}

.date_picker .pk_main .main_day{overflow: hidden;text-align: center;}
.date_picker .pk_main .main_day span{float: left;height: 25px;line-height: 25px;width: 25px;margin:2px 5.5px;cursor: pointer;}
.date_picker .pk_main .main_day span.na{color: #bbb;cursor: auto}
.date_picker .pk_main .main_day span:not(.na):hover,
.date_picker .pk_main .main_day span.active{color: #fff;background: #f18e00;border-radius: 29px;}

.date_picker .pk_bt {height: 32px;line-height: 32px;overflow: hidden;}
.date_picker .pk_bt span {float:right;color: #f18e00;cursor: pointer;margin: 0 10px;}
.date_picker .pk_bt span:hover{color: #f16600;}

@import 'index';

.floruitViewIndex{width:560px;height:550px;position:absolute;top:10px;left:45px;border:none;z-index:600;overflow-x:hidden;overflow-y:auto;
	.floruit_index{width:1200px;transform: scale(0.45,0.45);position:absolute;left:-330px;top:-780px;}
}


/**

 @Name： laydate 核心样式
 @Author：贤心
 @Site：http://sentsin.com/layui/laydate

**/

html{_background-image:url(about:blank); _background-attachment:fixed;}
.laydate_body .laydate_box, .laydate_body .laydate_box *{margin:0; padding:0;}
.laydate-icon,
.laydate-icon-default,
.laydate-icon-danlan,
.laydate-icon-dahong,
.laydate-icon-molv{height:22px; line-height:22px; padding-right:20px; border:1px solid #C6C6C6; background-repeat:no-repeat; background-position:right center;  background-color:#fff; outline:0;}
.laydate-icon-default{ background-image:url(../skins/default/icon.png)}
.laydate-icon-danlan{border:1px solid #B1D2EC; background-image:url(../skins/danlan/icon.png)}
.laydate-icon-dahong{background-image:url(../skins/dahong/icon.png)}
.laydate-icon-molv{background-image:url(../skins/molv/icon.png)}
.laydate_body .laydate_box{width:240px; font:12px '\5B8B\4F53'; z-index:99999999; *margin:-2px 0 0 -2px; *overflow:hidden; _margin:0; _position:absolute!important; background-color:#fff;}
.laydate_body .laydate_box li{list-style:none;}
.laydate_body .laydate_box .laydate_void{cursor:text!important;}
.laydate_body .laydate_box a, .laydate_body .laydate_box a:hover{text-decoration:none;cursor:pointer;}
.laydate_body .laydate_box a:hover{text-decoration:none;}
.laydate_body .laydate_box cite, .laydate_body .laydate_box label{position:absolute; width:0; height:0; border-width:5px; border-style:dashed; border-color:transparent; overflow:hidden; cursor:pointer;}
.laydate_body .laydate_box .laydate_yms, .laydate_body .laydate_box .laydate_time{display:none;}
.laydate_body .laydate_box .laydate_show{display:block;}
.laydate_body .laydate_box input{outline:0; font-size:14px; background-color:#fff;}
.laydate_body .laydate_top{position:relative; height:26px; padding:5px; *width:100%; z-index:99;}
.laydate_body .laydate_ym{position:relative; float:left; height:24px; cursor:pointer;}
.laydate_body .laydate_ym input{float:left; height:24px; line-height:24px; text-align:center; border:none; cursor:pointer;}
.laydate_body .laydate_ym .laydate_yms{position:absolute; left: -1px; top: 24px; height:181px;}
.laydate_body .laydate_y{width:121px; margin-right:6px;}
.laydate_body .laydate_y input{width:64px; margin-right:15px;}
.laydate_body .laydate_y .laydate_yms{width:121px; text-align:center;}
.laydate_body .laydate_y .laydate_yms a{position:relative; display:block; height:20px;}
.laydate_body .laydate_y .laydate_yms ul{height:139px; padding:0; *overflow:hidden;overflow:hidden;}
.laydate_body .laydate_y .laydate_yms ul li{float:left; width:60px; height:20px; line-height: 20px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.laydate_body .laydate_m{width:99px;}
.laydate_body .laydate_m .laydate_yms{width:99px; padding:0;}
.laydate_body .laydate_m input{width:42px; margin-right:15px;}
.laydate_body .laydate_m .laydate_yms span{display:block; float:left; width:42px; margin: 5px 0 0 5px; line-height:24px; text-align:center; _display:inline;}
.laydate_body .laydate_choose{display:block; float:left; position:relative; width:20px; height:24px;}
.laydate_body .laydate_choose cite, .laydate_body .laydate_tab cite{left:50%; top:50%;}
.laydate_body .laydate_chtop cite{margin:-7px 0 0 -5px; border-bottom-style:solid;}
.laydate_body .laydate_chdown cite, .laydate_body .laydate_ym label{top:50%; margin:-2px 0 0 -5px; border-top-style:solid;}
.laydate_body .laydate_chprev cite{margin:-5px 0 0 -7px;}
.laydate_body .laydate_chnext cite{margin:-5px 0 0 -2px;}
.laydate_body .laydate_ym label{right:28px;}
.laydate_body .laydate_table{ width:230px; margin:0 5px; border-collapse:collapse; border-spacing:0px; }
.laydate_body .laydate_table td{width:31px; height:19px; line-height:19px; text-align: center; cursor:pointer; font-size: 12px;}
.laydate_body .laydate_table thead{height:22px; line-height:22px;}
.laydate_body .laydate_table thead th{font-weight:400; font-size:12px; text-align:center;}
.laydate_body .laydate_bottom{position:relative; height:31px; line-height:20px; padding:5px; font-size:12px;}
.laydate_body .laydate_bottom #laydate_hms{position: relative; z-index: 1; float:left; }
.laydate_body .laydate_time{ position:absolute; left:5px; bottom: 26px; width:129px; height:125px; *overflow:hidden;}
.laydate_body .laydate_time .laydate_hmsno{ padding:5px 0 0 5px;}
.laydate_body .laydate_time .laydate_hmsno span{display:block; float:left; width:24px; height:19px; line-height:19px; text-align:center; cursor:pointer; *margin-bottom:-5px;}
.laydate_body .laydate_time1{width:228px; height:154px;}
.laydate_body .laydate_time1 .laydate_hmsno{padding: 6px 0 0 8px;}
.laydate_body .laydate_time1 .laydate_hmsno span{width:21px; height:20px; line-height:20px;}
.laydate_body .laydate_msg{left:49px; bottom:67px; width:141px; height:auto; overflow: hidden;}
.laydate_body .laydate_msg p{padding:5px 10px;}
.laydate_body .laydate_bottom li{float:left; height:20px; line-height:20px; border-right:none; font-weight:900;}
.laydate_body .laydate_bottom .laydate_sj{width:33px; text-align:center; font-weight:400;}
.laydate_body .laydate_bottom input{float:left; width:21px; height:20px; line-height:20px; border:none; text-align:center; cursor:pointer; font-size:12px;  font-weight:400;}
.laydate_body .laydate_bottom .laydte_hsmtex{height:20px; line-height:20px; text-align:center;}
.laydate_body .laydate_bottom .laydte_hsmtex span{position:absolute; width:20px; top:0; right:0px; cursor:pointer;}
.laydate_body .laydate_bottom .laydte_hsmtex span:hover{font-size:14px;}
.laydate_body .laydate_bottom .laydate_btn{position:absolute; right:5px; top:5px;}
.laydate_body .laydate_bottom .laydate_btn a{float:left; height:20px; padding:0 6px; _padding:0 5px;}
.laydate_body .laydate_bottom .laydate_v{position:absolute; left:10px; top:6px; font-family:Courier; z-index:0;}

/**

 @Name： laydate皮肤：默认
 @Author：贤心
 @Site：http://sentsin.com/layui/laydate

**/

.laydate-icon{border:1px solid #C6C6C6; background-image:url(icon.png)}

.laydate_body .laydate_box,
.laydate_body .laydate_ym,
.laydate_body .laydate_ym .laydate_yms,
.laydate_body .laydate_table,
.laydate_body .laydate_table td,
.laydate_body .laydate_bottom #laydate_hms,
.laydate_body .laydate_time,
.laydate_body .laydate_bottom .laydate_btn a{border:1px solid #ccc;}

.laydate_body .laydate_y .laydate_yms a,
.laydate_body .laydate_choose,
.laydate_body .laydate_table thead,
.laydate_body .laydate_bottom .laydte_hsmtex{background-color:#F6F6F6;}

.laydate_body .laydate_box,
.laydate_body .laydate_ym .laydate_yms,
.laydate_body .laydate_time{box-shadow: 2px 2px 5px rgba(0,0,0,.1);}

.laydate_body .laydate_box{border-top:none; border-bottom:none; background-color:#fff; color:#333;}
.laydate_body .laydate_box input{color:#333;}
.laydate_body .laydate_box .laydate_void{color:#ccc!important; /*text-decoration:line-through;*/}
.laydate_body .laydate_box .laydate_void:hover{background-color:#fff!important}
.laydate_body .laydate_box a, .laydate_body .laydate_box a:hover{color:#333;}
.laydate_body .laydate_box a:hover{color:#666;}
.laydate_body .laydate_click{background-color:#eee!important;}
.laydate_body .laydate_top{border-top:1px solid #C6C6C6;}
.laydate_body .laydate_ym .laydate_yms{border:1px solid #C6C6C6; background-color:#fff;}
.laydate_body .laydate_y .laydate_yms a{border-bottom:1px solid #C6C6C6;}
.laydate_body .laydate_y .laydate_yms .laydate_chdown{border-top:1px solid #C6C6C6; border-bottom:none;}
.laydate_body .laydate_choose{border-left:1px solid #C6C6C6;}
.laydate_body .laydate_chprev{border-left:none; border-right:1px solid #C6C6C6;}
.laydate_body .laydate_choose:hover,
.laydate_body .laydate_y .laydate_yms a:hover{background-color:#fff;}
.laydate_body .laydate_chtop cite{border-bottom-color:#666;}
.laydate_body .laydate_chdown cite, .laydate_body .laydate_ym label{border-top-color:#666;}
.laydate_body .laydate_chprev cite{border-right-style:solid; border-right-color:#666;}
.laydate_body .laydate_chnext cite{border-left-style:solid; border-left-color:#666;}
.laydate_body .laydate_table td{border:none;  height:21px!important; line-height:21px!important; background-color:#fff;}
.laydate_body .laydate_table .laydate_nothis{color:#999;}
.laydate_body .laydate_table thead{height:21px!important; line-height:21px!important;}
.laydate_body .laydate_table thead th{border-bottom:1px solid #ccc;}
.laydate_body .laydate_bottom{border-bottom:1px solid #C6C6C6;}
.laydate_body .laydate_bottom #laydate_hms{background-color:#fff;}
.laydate_body .laydate_time{background-color:#fff;}
.laydate_body .laydate_bottom .laydate_sj{border-right:1px solid #C6C6C6; background-color:#F6F6F6;}
.laydate_body .laydate_bottom input{background-color:#fff;}
.laydate_body .laydate_bottom .laydte_hsmtex{border-bottom:1px solid #C6C6C6;}
.laydate_body .laydate_bottom .laydate_btn{border-right:1px solid #C6C6C6;}
.laydate_body .laydate_bottom .laydate_v{color:#999}
.laydate_body .laydate_bottom .laydate_btn a{border-right:none; background-color:#F6F6F6;}
.laydate_body .laydate_bottom .laydate_btn a:hover{color:#000; background-color:#fff;}

.laydate_body .laydate_m .laydate_yms span:hover,
.laydate_body .laydate_y .laydate_yms ul li:hover,
.laydate_body .laydate_table td:hover,
.laydate_body .laydate_time .laydate_hmsno span:hover{background-color:#F3F3F3}


//
.activityIndex{
	.activity-ul-content{padding:10px 0 0;border-bottom:2px solid #e8e8e8;margin-bottom:30px;
		.ul-coentent{overflow: auto;margin-bottom:-2px;padding-top:5px;display: inline-block;max-width:100%;padding-right:160px;position:relative;bottom:-2px;
			.item{float:left;margin-right:2px;font-size:14px;border:1px solid #e8e8e8;padding:3px 15px 4px;border-top-left-radius:5px;border-top-right-radius:5px;border-bottom:2px solid #e8e8e8;
				background-color:#e8e8e8;cursor:pointer;color:#777;margin-top:10px;
				&.active{border-color:#f18e00;background-color:#f18e00;color:#fff;
					&:hover{color:#FFF;}
				}
				&:hover{color:#f18e00;border-bottom:2px solid #f18e00;}
				&.add-activity-button{padding-bottom:2px;border-radius:5px;color:#555;position:absolute;right:30px;top:6px;
					&:hover{color:#f18e00;border-color:#f18e00;}
				}
			}
		}
	}
	.bottom_sure{position:absolute;left:240px;top:690px;background-color:#f18e00;color:#fff;padding:5px 20px;border-radius:6px;cursor: pointer;
		&.del-buton{background-color:#ddd;left:0;color:#555;}
	}
	#wap_index{position:relative;padding-left:400px;min-height:530px;
		.activity-view-content{position:absolute;left:0;top:0;width:375px;border:1px solid #e8e8e8;height:667px;
			.view-html{width:375px;overflow: auto;height:100%;}
			.view-header{overflow: hidden;
				img{width:100%;height:auto;float:left;}
			}
			.view-body{min-height:400px;background-size: 100% 100%;padding:0 20px 20px;overflow: hidden; background-repeat: no-repeat;background-position: left top;}
			.view-footer{overflow: hidden;
				img{width:100%;height:auto;float:left;}
			}
			.goods-item{position:relative;width:49%;margin-right:2%;float:left;box-sizing:border-box;margin-top:20px;
				.icon{position:absolute;top:0;left:20px;width:40px;height:40px;background-size:100% auto;background-repeat: no-repeat;background-position: left top;}
				.goods-detail-content{border-radius:7px;background-color: #fff;box-sizing:border-box;overflow: hidden;padding:10px 10px 20px;}
				.goods-img{width:100%;height:auto;margin-top:10px;}
				.spe-pri{display: flex;align-items:center;justify-content: space-between;
					.price{
						span{font-size:14px;}
						.price_vik{font-size:10px;text-align:right;}
					}
				}
				&:nth-child(2n){margin-right:0;}
				.add-bottom-buy-btn{margin-top:15px;text-align:center;
					span{display: inline-block;padding: 0 5px;background-color: #ec3576;color: #fff;border-radius: 10px;font-size: .12px;}
				}
			}
		}
		.activity-content{
	
		}
		.wap_slider_container{overflow: hidden;
			.content-do-item{margin-bottom:20px;border-right:1px solid #e8e8e8;
				&.is-float-tiem{float:left;width:230px;margin-right:20px;}
				.s-title{font-size:16px;font-weight:bold;color:#555;
					.title-input{border:1px solid #e8e8e8;margin-right:40px;padding:3px 10px;border-radius:6px;font-size:14px;width:140px;
						&.large{width:350px;background-color:#999;color:#fff;}
					}
				}
				.do-content{padding:10px 10px 10px;position:relative;overflow: hidden;}
			}
			.ipt-item{display: inline-block;position: relative;padding-left:50px;height:30px;margin-right:60px;margin-top:10px;
				&.mid-input-title{padding-left:80px;}
				&.large{padding-left:100px;}
					.title{position: absolute;left:0;top:0;font-size:14px;color:#555;line-height:30px;}
					.value{border:1px solid #e8e8e8;font-size:14px;height:30px;padding:0 10px;border-radius:6px;width:210px;
						&.small{width:60px;}
						&.mid-ipy{width:80px;}
					}
				}
			.img_item{
				&.smalImgItem{
					&:nth-child(5n){margin-right:42px;}
				}
			}

			.upimgbox{width:100px;height:100px;border-radius:5px;display:inline-block;position:relative;
				.upImgGuide{position:absolute;left:0;top:0;width:100px;height:100px;}
				&.isAddBtn{
					&:before,&:after{width:30px;height:3px;left:35px;top:48px;}
				}
				&.active{border:1px dashed #aaa;}
				.upFileBox{position:absolute;width:100px;height:100px;left:0;top:0;opacity:0;cursor:pointer;z-index:800;}
			}
			.bg-content-do{float:left;margin-right:40px;margin-top:10px;
				.bg-title{font-size:14px;text-align:center;color:#555;padding-bottom: 10px;}
			}
		} 
	}
}
//优惠券设置
.couponContainer{padding-right:40px;
	.con_search{padding:0 0 10px;height:40px;
		.search_item{height:40px;line-height:30px;font-size:14px;color:#333;margin-right:80px;float:left;
			.label{}
			.input_item{width:160px;height:30px;margin:5px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
				&.select{padding-right:25px;background:#fff url('/img/index/my_arrow.png') no-repeat 140px center;}
			}
			&:nth-last-of-type(1){margin-right:0;}
		}
		.btn_group{display:inline-block;height:40px;margin-left:40px;width:140px;display:inline-block;
			.btn{font-size: 14px;color: #fff;background: #f18e00;margin-right: 20px;display: inline-block;width: 60px;height: 30px;padding: 0;line-height: 30px;margin-top: 5px;cursor:pointer;
				&:nth-last-of-type(1){margin-right:0px;}
			}
			.manage_btn{display:inline-block;margin-right:20px;
				.del_btn{background:#f5483a;}
				.cancel_btn{background:#f5483a;}
			}
			&.btn_group_left{margin-left:0;margin-top:10px;width:220px;}
		}
	}
	.table_con{background:#fff;border-radius: 6px;border: 1px solid #ccc;margin-top: 20px;
		.tab_tit{background:#eee;overflow:hidden;border-bottom:1px solid #ddd;
			span{height:50px;float:left;line-height:50px;border-right:1px solid #ddd;width:17%;font-weight:bold;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;text-align:center;color:#666;
				&:nth-of-type(1){width:19%;}
				&:nth-of-type(3){width:19%;}
				&.tab_check{width:5%;background:url(/img/checkbox.png) center no-repeat;cursor:pointer;
                    &:hover{background-image:url(/img/checkbox_hover.png);}
                    &.active{background-image:url(/img/checkbox_active.png);}
                }
                &:nth-last-child(1){border-right:none;width:10%;}
			}
			&.tab_cell{background:transparent;
                span{font-weight:normal;user-select: text;-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;
                	em{color:#f5483a;padding-right:20px;cursor:pointer;
                		&:nth-last-child(1){padding-right:0px;color:#f18e00;}
                	}
                }
            }
            &.tab_cell:hover{background:#fafafa;}
            &:nth-last-child(1){border-bottom:none;}
            &.tab_check_tit{
            	span{width:16%;
            		&.tab_check{width:5%;}
            		&:nth-of-type(2){width:19%;}
					&:nth-of-type(4){width:17%;}
            		&:nth-last-child(1){border-right:none;width:9%;}
            	}
            }
		}
		&.activity_table{
			.tab_tit{
				span{width:13%;
					&:nth-of-type(2){width:18%;}
					&:nth-of-type(3){width:16%;}
				}
				&.tab_check_tit{
	            	span{width:12%;
	            		&.tab_check{width:5%;}
	            		&:nth-of-type(3){width:18%;}
						&:nth-of-type(5){width:16%;}
	            		&:nth-last-child(1){border-right:none;width:10%;}
	            	}
	            }
			}
		}
	}
	.tab_page{text-align:right;margin-top:20px;
        span{height:25px;width:25px;display:inline-block;border:1px solid #ddd;line-height:25px;text-align:center;color:#333;font-size:12px;cursor:pointer;margin:0 5px;
            &:hover,&.active{background:#f18e00;border-color:#f18e00;color:#fff;}
        }
        i{height:25px;width:25px;display:inline-block;background:url(/img/back_icon.png) center no-repeat;vertical-align:middle;cursor:pointer;
            &.page_bks{background-image:url(/img/backs_icon.png)}
            &.page_fd{background-image:url(/img/next_icon.png)}
            &.page_fds{background-image:url(/img/nexts_icon.png)}
        }
    }
    .module_wrap{position:fixed;background:rgba(0,0,0,0.8);top:0;left:0;width:100%;height:100%;z-index:1000;
    	.module{width:516px;height:274px;background:#fff;border-radius:8px;padding:20px 30px;position:absolute;top:50%;left:50%;margin-top:-137px;margin-left:-288px;color:#333;box-sizing:border-box;
    		.module_title{width:100%;height:30px;font-size:16px;text-align:center;margin-bottom:20px;}
    		.module_content{
    			.input_box{margin-bottom:12px;
    				.label{display:inline-block;width:100px;height:30px;line-height:30px;}
    				.input_item{width:160px;height:30px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;color:#666;
    					&::-webkit-input-placeholder {color:#666;}
						&.select{padding-right:25px;background:#fff url('/img/index/my_arrow.png') no-repeat 140px center;color:#666;}
					}
					.setTimebox{height:30px;width:160px;border:1px solid #ccc;background:#fff;line-height:30px;padding-left:10px;display: inline-block;color:#666;font-size:13px;box-sizing:border-box;border-radius:4px;}
					.line{padding:0 10px;}
    			}
    		}
    		.btn_group_box{display:flex;justify-content: center;}
    		.btn_group{display:inline-block;height:40px;width:200px;display:inline-block;margin-top:16px;
				.btn{font-size: 15px;color: #fff;background: #f18e00;margin-right: 30px;display: inline-block;width: 80px;height: 32px;padding: 0;line-height: 32px;margin-top: 5px;cursor:pointer;
					&:nth-last-of-type(1){margin-right:0px;}
				}
	    	}
	    	&.coupon_add_module{width:auto;height:auto;
				.search_item{height:40px;line-height:30px;font-size:14px;color:#333;margin-bottom:20px;}
				.input_item{width:210px;height:30px;margin-right:5px;margin-bottom:5px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
					&:nth-last-of-type(1){margin-bottom:0px;}
				}
				.label{width:96px;display:inline-block;height:30px;line-height:30px;position:relative;margin-right:10px;
					em{color:#f5483a;font-size:20px;position:absolute;top:0;right:0;}
					&:nth-last-of-child(1){margin-right:0}
				}	
				.radio_group{margin-bottom:0px;
					label{margin-right:60px;font-size:14px;
						.radio{background:url('/img/live/radio.png') no-repeat 12px center;background-size:60px;width:36px;height:12px;margin-left:-10px;}
						.radio.active{background-position: -36px center;}
						&:nth-last-of-type(1){margin-right:0}
					}
				}
				.tab_box{padding-left: 110px;font-size: 14px;margin-bottom: 20px;
					.tab_item{display:block;
						label{margin-right:40px;
							.label_text{}
							.label_text_right{float:none;}
							.input_short{width:100px;}
							&:nth-last-of-type(1){margin-right:0}
						}
						.select{padding-right:25px;background:#fff url('/img/index/my_arrow.png') no-repeat 80px center;}
						.input_mid{width:140px;}
						&.hide{display:none;}
						.setTimebox{height:30px;width:140px;border:1px solid #ccc;background:#fff;line-height:30px;padding-left:10px;display: inline-block;color:#666;}
						.link_line{display: inline-block;padding: 0 10px;height:30px;line-height:30px;}
						&.sec_tab_item{
							label{display:block;margin-bottom:8px;
								.label_text{width:84px;display:inline-block;}
							}
						}
					}
					&.no_margin{margin-bottom:0}
				}
				.btn_group_box{display:flex;
					.btn_group{margin: 20px auto 0px;
						.btn:nth-last-of-type(1){margin-right:0;}
					}
				}
			}
	    }
    }
}
//满赠活动
.fullGiftContainer{padding-right: 40px;
	.go_back_btn{color:#f18e00;font-size: 20px;margin-top: -10px;margin-bottom: 10px;cursor: pointer;}
	.content{padding:20px 0 30px;border-bottom:1px solid #ccc;color:#333;font-size:16px;
		&.content_row{display:flex;align-items:center;}
		&.no_border{border-bottom:none;}
		.title{font-weight:900;font-size:16px;margin-bottom:20px;
			&.title_left{float:left;margin-right:60px;margin-bottom:0px;}
		}
		.radio_group{float:left;
			label{margin-right:30px;
				.radio{background:url('/img/live/radio.png') no-repeat 12px center;background-size:60px;width:32px;height:12px;margin-left:-10px;}
				.radio.active{background-position: -36px center;}
				&:nth-last-of-type(1){margin-right:0}
			}
		}
		.input_group{margin-bottom:40px;
			label{margin-right:60px;
				.label{margin-right: 8px;}
			}
			.input_item{width:160px;height:30px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
				&.input_big{width:180px;}
			}
			.setTimebox{height:30px;width:160px;border:1px solid #ccc;background:#fff;line-height:30px;padding-left:10px;display: inline-block;color:#666;box-sizing:border-box;border-radius:4px;font-size:14px;}
			.link_line{padding:0 10px;}
			&.no_margin{margin-bottom:0px;}
			&.base_input_group{display:flex;align-items:center;}
			.btn{padding:6px 0;width:70px;cursor:pointer;margin-left:60px;
				&:nth-last-of-type(1){margin-left:20px;}
			}
		}
		.goods_detail{display:flex;align-items: center;
			img{width:140px;height:140px;margin-right:30px;}
			.detail_desc{
				.goods_title{margin-bottom:58px;}
				.goods_price_box{display:flex;align-items:flex-end}
				.goods_price{margin-right:80px;display: flex;flex-direction: column;
					.num_limit{font-size:12px;}
				}
				.goods_storage{float:left;}
			}
		}
		.last_btn_group{margin-top:40px;}
		.btn{float:left;width:80px;margin-left:100px;font-size:16px;cursor:pointer;}
	}
}
//满减活动
.fullReductionContainer{
	.add_btn{color:#f18e00;font-size:16px;border:1px solid #ccc;border-radius:4px;margin-bottom:20px;width: 130px;height: 32px;line-height: 32px;text-align: center;background: #fff;}
	.rule_group{
		.rule_item{padding:15px;border:1px solid #ccc;border-radius:4px;display:flex;align-items:center;width:492px;background:#fff;margin-bottom:20px;position:relative;
			.del_btn{position:absolute;width:24px;height:24px;top:-12px;right:-12px;background:url(/img/close.png) no-repeat left top;background-size:24px 24px;cursor:pointer;}
			.input_group{margin-bottom:0px;
				label{margin-right:60px;
					.label{margin-right: 8px;}
					&:nth-last-of-type(1){margin-right:0px;}
				}
				.input_item{width:160px;height:30px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
					&.input_big{width:180px;}
				}
			}
		}
	}
}
//VIP限时返利
.vipLimitedReturn{padding-bottom:60px;
    .content{
        .base_select{margin-bottom:20px;
            .input_item{
                &.select{width:100px;}
            }
        }
    }
    .rule_group{position:relative;
        .rule_item{width:536px;
            .input_group{
                .select{width: 80px;appearance:normal;-webkit-appearance:normal;}
                label{margin-right:88px;
                    &:nth-last-of-type(1){margin-right:0px;}
                }
            }
            
        }
        .add_icon{background: url(../img/add.png) no-repeat left top;background-size: 40px 40px;width: 40px;height: 40px;position: absolute;left: 588px;bottom: 11.5px;cursor:pointer;}
    }
    .activity_rule{margin-top: 30px;
        label{display: flex;}
        textarea{width: 458px;height: 100px;border: none;outline: none;border: 1px solid #ccc;border-radius: 4px;padding: 10px;box-sizing: border-box;white-space:normal; word-break:break-all;}
    }
    .edit_tip{margin-top: 90px;margin-left: 80px;
        .title{margin-bottom:10px;}
        .text{margin-bottom:5px;}
    }
    select{-webkit-appearance: menulist;}
}
//大型促销
.LargeCouponContainer{
	.activity_url{margin-left:100px;
		em{color:#f18e00;}
		.url{padding: 5px 16px 5px 10px;color:#666;background:#fff;border:1px solid #e1e1e1;border-radius:4px;}
	}
	.content{
		.component_group{margin-bottom:30px;color:#333;
			label{margin-bottom:30px;display:block;
				.label{width:144px;display:inline-block;}
				.component_item{display:inline-block;
					.component_group_item{display:inline-block;}
					em{border:1px solid #ccc;background:#fff;display: inline-block;margin-right: 20px;cursor: pointer;height: 32px;line-height: 32px;width: 86px;text-align: center;position:relative;
						&.red{color:#e95513;border:1px solid #e95513;}
						.del_btn{width:20px;height:20px;background:url(/img/close.png) no-repeat left top;background-size:20px 20px;cursor:pointer;position:absolute;top:-10px;right:-10px;display:none;
							&.hide{display:none;}
						}
						&:hover{
							.del_btn{display:inline-block;
								&.hide{display:none;}
							}
						}
					}
				}
				&.single_select{
					.component_item{width:auto;height:32px;
						.component_group_item{
							&:nth-of-type(1){
								em{
									&.red{border-right:1px solid #e95513;}
								}
							}
							&:nth-of-type(2){
								em{
									&.red{border-left:1px solid #e95513;}
								}
							}
						}
					}
				}
			}
		}
		.module_group{border:1px solid #ddd;border-radius:6px;width:400px;margin-left:60px;min-height:800px;height:auto;float:left;position:relative;padding-bottom:100px;
			.module{position:relative;
				.del_btn{position:absolute;width:30px;height:30px;background:url(/img/close.png) no-repeat left top;top:-15px;right:-15px;background-size:30px 30px;display:none;cursor:pointer;}
				&:hover{
					.del_btn{display:block;}
				}
				&.active{border:1px solid #f18e00;
					.no_data{border:none;}
				}
			}
			.coupon{display:flex;flex-direction: row;flex-wrap:wrap;position:relative;border-radius:2px;padding:0 16px;margin-bottom:16px;
				.coupon_box_wrap{width: 100%;overflow-x: scroll;display: flex;
					&::-webkit-scrollbar{display:block;}
					&::-webkit-scrollbar-thumb{background: rgba(240,0,0,0.3);}
				}
				.coupon_item{width:112px;height:100px;padding: 20px 120px 20px 60px;display:flex;justify-content: center;flex-direction:column;align-items: center;flex-basis: 112px;flex-shrink: 0;white-space: nowrap;background:url(/img/promotion/youhuiquan12.png) no-repeat center;background-size: 100% 100%;color:#fff;box-sizing: border-box;
					&:nth-of-type(1){margin-right: 6px;}
					.coupon_price{color:#fff;font-size:24px;margin-bottom:10px;
						.unit_icon{font-size:12px;}
					}
					.coupon_spec{font-size:14px;}
					.coupon_discount_max{font-size: 12px;}
					&.single_coupon_item{width:160px;margin-right:0;}
					&.multi_coupon_item{padding:0;background: url(/img/promotion/youhuiquan345.png) no-repeat center;background-size: 100% 100%;float: left;width: 111px;box-sizing: border-box;height: 180px;text-align: center;margin-right:16px;box-sizing: border-box;flex-basis: 111px;
						.price{
			                span{margin-bottom:10px;}
			            }
			            .coupon_spec{font-size:16px;color:#fff;margin-bottom:5px;}
			            .coupon_discount_max{color:#fff;font-size:14px;margin-bottom:10px;}
					}
				}
			}
			.full_reduction{padding:16px;display:flex;justify-content:space-between;align-items:center;margin:0;
				.full_reduction_item{width:100px;height:120px;padding: 30px 62px 36px;background:url(/img/promotion/manjian.png) no-repeat center;background-size:cover;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:14px;color:#fff;box-sizing:border-box;
					.coupon_price{font-size: 15px;text-align: center;
						span{display:block;}
					}
				}
			}
			.goods{display:flex;justify-content: space-between;flex-direction: row;flex-wrap:wrap;box-sizing:border-box;
				.goods_head{width:360px;height: 60px;margin:0 auto;background: url(/img/promotion/quanbu.png) no-repeat center;background-size: cover;margin-top: 18px;}
				.goods_box{display: flex;flex-wrap: wrap;height:100%;justify-content: space-between;padding:16px 16px 0;width:100%;box-sizing:border-box;}
				.goods_item{position:relative;padding:10px;background: #fff;border-radius: 4px;margin-bottom:20px;box-sizing: border-box;
					.goods_image_box{width:154px;height:auto;position:relative;margin-bottom:10px;
						img{width:154px;height:154px;}
					}
					.goods_info{color:#333;
						.goods_title{font-size:15px;margin-bottom:5px;display:block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 154px;}
						.goods_desc{font-size:13px;margin-bottom:5px;display:block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 154px;}
						.goods_price{font-size:10px;display:flex;flex-direction:column;
							.activity_price{color:#e95513;font-size:16px;}
							.origin_price{font-size:9px;text-decoration:line-through;color:#999;}
						}
					}
					.cart{position:absolute;right:10px;bottom:12px;width:30px;height:30px;border-radius:50%;background:url(/img/promotion/shopping.png) no-repeat center;background-size:30px 30px;}
					.goods_flag{position: absolute;top: 0px;left: 0px;width:40px;border:none;
						img{width:100%;height:auto;border:none;}
					}
				}
			}
			.goods_group{
				.group_tab_wrap{width:100%;color: #fff;background: #cc3333;
					.group_tab_box{height:40px;line-height:40px;display:flex;overflow-x:auto; justify-content: space-between;
						&::-webkit-scrollbar{display:block;}
						&::-webkit-scrollbar-thumb{background: rgba(255,255,255,0.3);}
						.tab_item{ text-align: center;color:#fff;font-size:14px;white-space: nowrap;flex-basis: 60px;flex-shrink: 0;cursor:pointer;
							&:nth-last-of-type(1){border:none;margin-right:0;}
							&.active{font-size: 15px;font-weight: 900;}
						}
					}
				}
				.goods_box{display:none;
					.goods_item{margin-right:0;}
					&.active{display:flex;justify-content:space-between;}
				}
			}
			.showcase{
				.title{width: 100%;height: 50px;display: flex;align-items: center;justify-content: center;background: #fff;margin-bottom:10px;
					span{font-size: 20px;background: #fff;color: #cc3366;display: flex;align-items: center;
						em{margin-right:50px;display: inline-block;width: 20px;height: 20px;background: url(/img/promotion/title_icon.png) no-repeat center;background-size: 20px 20px;
							&:nth-of-type(2){margin-left: 50px;margin-right: 0;}
						}
					}
				}
				.goods_list{display: flex;flex-wrap: wrap;background: #fff;border-radius: 5px;margin: 16px;padding: 10px;padding-bottom:0;
					.goods_item{width: 88px;border: 1px solid #e1e1e1;border-radius: 4px;padding: 10px;display: flex;flex-direction: column;position: relative;margin-right: 5px;margin-bottom:10px;
						.goods_image_box{width: 90px;height: 90px;margin-bottom: 10px;
							img{width:100%;height:100%;}
						}
						.goods_title{margin-bottom: 5px;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 90px;}
						.goods_price{font-size: 11px;
							.red{font-size:15px;color:#e95513;}
						}
						.origin_price{font-size: 10px;text-decoration: line-through;color: #999;}
						.cart{position:absolute;right:5px;bottom:8px;width:20px;height:20px;border-radius:50%;background:url(/img/promotion/shopping.png) no-repeat center;background-size:20px 20px;}
					}
				}
			}
			.header_image{width:400px;position:relative;
				img{width:100%;height:auto;display:block;}
				.activity_rule{height: 26px;width: 56px;line-height: 26px;text-align: center;font-size: 15px;position: absolute;right: 0px;top: 50%;margin-top:-13px;border-top-left-radius: 26px;border-bottom-left-radius: 26px;background: #ff9999;border:none;color: #fff;cursor:pointer;
					&.active{border:1px solid #f18e00;}
				}
				.no_data{width:auto;margin:10px;}
			}
			.footer_image{width:400px;height:100px;position:absolute;bottom:0;
				img{width:100%;height:100px;display:block;}
			}
			.background_image{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;
				img{width:100%;height:100%;display:block;}
			}
			.no_data{width:100%;display:flex;align-items:center;justify-content: center;min-height:100px;border:1px solid #ddd;margin-bottom:10px;}
			.order{position:absolute;top:0;left:-70px;display:flex;flex-direction:column;border:1px solid #f7c976;border-radius:4px;overflow:hidden;
				span{height:30px;line-height:30px;text-align:center;background:#f7c976;color:#fff;}
				input{width:60px;height:26px;line-height:26px;text-align:center;border:none;}
			}
			
		}
		.coupon_module{position: absolute;top: 0px;background: #f1f1f1;padding: 20px;border: 1px solid #ddd;border-radius: 6px;width:780px;right:-860px;
			.jiantou{width:20px;height:38px;background:url(/img/jiantou.png) no-repeat left top;background-size:20px 38px;position:absolute;top:30px;left:-21px;}
			.title{border-bottom:1px solid #ddd;padding-bottom:20px;color:#333;font-size:16px;font-weight:600;padding-left:20px;}
			.module_content{
				.add_btn{width:100px;text-align:center;padding:5px;color:#f18e00;margin:20px;margin-left:0;background:#fff;border-radius:4px;border:1px solid #f18e00;cursor:pointer;
					&.gray{background:#ccc;color:#fff;border:none;}
				}
				.table_box{margin-bottom:30px;background: #fff;padding: 20px;border-radius: 4px;position: relative;
					.close_btn{position: absolute;width: 30px;height: 30px;background: url(/img/close.png) no-repeat left top;top: -15px;right: -15px;background-size: 30px 30px;display: none;cursor: pointer;}
					&:hover{
						.close_btn{display:block;}
					}
				}
			}
			.add_module_wrap{height:100%;background: rgba(0,0,0,0.8);position:fixed;width:100%;top:0;left:0;z-index:999;
				.add_module{position:absolute;width:800px;height:730px;top:50%;left:50%;margin-left:-400px;margin-top:-365px;background:#fff;border-radius:4px;border:1px solid #ddd;padding:20px 26px;
					.close_btn{position:absolute;right:8px;top:8px;width:16px;height:16px;background:url(/img/close_mod.png) no-repeat left top;background-size:14px 14px;cursor:pointer;}
					.module_head{border-bottom:1px solid #ddd;display:flex;justify-content:space-between;align-items:center;padding-bottom:16px;padding-top:6px;
						.title{font-size:16px;color:#333;padding:0;margin:0;border:none;}
						.seach_box{width:160px;height:28px;border:1px solid #ddd;border-radius:6px;padding-right:20px;position:relative;overflow:hidden;margin-right:5px;
							input{border:none;height:28px;width:150px;padding-left:10px;}
							.search_icon{width:16px;height:16px;background:url(/img/search.png) no-repeat center;background-size:16px 16px;position:absolute;top:5px;right:5px;}
						}
					}
					.btn_group{display: flex;align-items: center;justify-content: center;margin-top: 10px;
						.btn {margin-left:0;
							&.cancel_btn{background:#ddd;border:1px solid #ddd;}
							&.save_btn{margin-left:20px;}
						}
					}
					&.goods_group_module{width: 900px;margin-left: -450px;height:auto;max-height:600px;margin-top: -300px;
						.btn_group{margin-top: 30px;}
						.table_con{
							.tab_tit{
								span{width:16%;}
								&:nth-last-child(1){border-bottom:1px solid #ddd;}
							}
							.tab_box{height:auto;max-height:400px;overflow:auto;
								&::-webkit-scrollbar{display:block;}
							}
						}
					}
					.tab_box{
						&::-webkit-scrollbar{display:block;}
					}
				}
			}
			.table_con{background:#fff;border-radius: 4px;border: 1px solid #ccc;margin-top: 20px;
				.tab_tit{background:#eee;overflow:hidden;border-bottom:1px solid #ddd;
					span{height:50px;float:left;line-height:50px;border-right:1px solid #ddd;width:24%;font-weight:bold;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;text-align:center;color:#666;
						&:nth-of-type(1){width:8%;}
						&:nth-of-type(3){width:19%;}
						&.tab_check{width:8%;background:url(/img/checkbox.png) center no-repeat;cursor:pointer;display:flex;align-items:center;justify-content:center;
		                    &:hover{background-image:url(/img/checkbox_hover.png);}
		                    &.active{background-image:url(/img/checkbox_active.png);}
		                    &.disabled{background:none;
		                    	em{width:16px;height:16px;background:#ccc;}
		                    }
		                }
		                &:nth-last-child(1){border-right:none;}
					}
					&.tab_cell{background:transparent;
		                span{font-weight:normal;user-select: text;-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;
		                	em{color:#f5483a;padding-right:20px;cursor:pointer;
		                		&:nth-last-child(1){padding-right:0px;color:#f18e00;}
		                	}
		                }
		            }
		            &.tab_cell:hover{background:#fafafa;}
		            &:nth-last-child(1){border-bottom:none;}
				}
				&.coupon_table_con{
					.tab_tit{
						span{width:26%;
							&:nth-of-type(1){width:26%;}
							&:nth-of-type(2){width:21%;}
							&:nth-of-type(3){width:26%;}
						}
					}
				}
			}
			.tab_footer{margin-top:16px;display:flex;margin-top:16px;
				.input_item{width:80px;height:26px;line-height:26px;border:none;outline:none;border:1px solid #ddd;border-radius:2px;margin-right:5px;padding-left:10px;}
				.foot_left{margin-right:100px;}
			}
			.module_save_btn{float:none;margin:30px auto 0;}
		}
		.full_reduction_module{right: -620px;width: 530px;
			.module_content{
				.add_btn{width:120px;}
			}
			.rule_group{
				.rule_item{padding:15px;border:1px solid #ccc;border-radius:4px;display:flex;align-items:center;width:492px;background:#fff;margin-bottom:20px;position:relative;
					.del_btn{position:absolute;width:24px;height:24px;top:-12px;right:-12px;background:url(/img/close.png) no-repeat left top;background-size:24px 24px;cursor:pointer;}
					.input_group{margin-bottom:0px;
						label{margin-right:60px;
							.label{margin-right: 8px;}
							&:nth-last-of-type(1){margin-right:0px;}
						}
						.input_item{width:160px;height:30px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
							&.input_big{width:180px;}
						}
					}
				}
			}
		}
		.goods_module{width: 900px;right: -980px;
			.module_content{
				.add_btn_small{width:60px;background:#f18e00;color:#fff;margin:0;margin-bottom:20px;}
				.goods_list{background: #fff;padding: 20px;border-radius: 4px;position:relative;
					.open_btn{float:right;color:#f18e00;cursor:pointer;
						.open_icon{width:18px;height:10px;background:url(/img/pack_up.png) no-repeat center;background-size:16px 10px;display:inline-block;padding-right:10px;}
						&.pack_up{
							.open_icon{background:url(/img/open.png) no-repeat center;}
						}
					}
					.goods_count{
						.red{color:#e95513;}
					}
					.table_con{
						.tab_tit{
							span{width:18%;
								&:nth-of-type(1){width:11%;}
								&:nth-of-type(3){width:18%;}
								&:nth-of-type(4){width:16%;}
							}
							&.tab_cell{
								.sort{background:url(/img/sort.png) no-repeat center;background-size:27px 28px;}
							}
							&:nth-of-type(1){border:1px solid #ddd;}
						}
						.tab_content{max-height:300px;overflow:auto;}
					}
					.input_item{width: 60px;height: 24px;line-height: 20px;background: #fff;border: none;outline: none;border: 1px solid #ddd;border-radius: 2px;margin-left: 10px;padding-left:10px;}
					.head{padding-bottom: 16px;border-bottom: 1px solid #ddd;width:100%;
						.group_order{padding-right:100px;}
					}
					.goods_content{width:100%;}
					.group_name{margin-top:16px;
						.input_item{width:140px;}
					}
					.goods_set{margin-top: 16px;display: flex;align-items: center;margin-bottom: 20px;
						.btn{margin-left: 20px;width: 70px;height: 30px;padding: 0;line-height: 30px;}
					}
					.goods_count{padding-left: 20px;}
					.del_btn{display:none;}
					&:hover{
						.del_btn{display:block;}
					}
				}
			}
		}
		.base_module{position:absolute;top:0;right:-340px;float:left;width:260px;margin-left:50px;
			.title{text-align: center;padding-left: 0;}
			.upload_box{border:1px solid #ddd;width:100px;height:100px;position:relative;border-radius:5px;cursor:pointer;background:#f7f7f7;margin:0 auto;
				&:before,&:after{content: '';display: block;width: 30px;height: 3px;background-color: #d2d2d2;position: absolute;top:50%; left: 50%;margin-top:-1.5px;margin-left:-15px;border-radius: 7px;}
				&:before{transform: rotate(90deg);}
				img{width:100%;height:100%;}
				.upload_input{position: absolute;width:100%;height: 100%;left: 0;top: 0;opacity: 0;cursor: pointer;z-index: 8;}
			}
			textarea{outline: none;width: 100%;min-height: 160px;border: 1px solid #ddd;border-radius: 2px;padding:10px;box-sizing:border-box;}
		}
		.module_wrap{position:fixed;background:rgba(0,0,0,0.8);top:0;left:0;width:100%;height:100%;z-index:1000;
	    	.module{width:460px;height:460px;background:#fff;border-radius:8px;padding:20px 30px;position:absolute;top:50%;left:50%;margin-top:-230px;margin-left:-200px;color:#333;box-sizing:border-box;word-wrap: break-word;
	    		.close_btn{position: absolute;width: 20px;height: 20px;background: url(/img/close_mod.png) no-repeat left top;top: 10px;right: 5px;background-size: 20px 20px;cursor: pointer;}
	    		.title{width:100%;height:30px;font-size:16px;text-align:center;padding:0;padding-bottom:10px;}
	    		.module_content{
	    			.search_item{margin-bottom:12px;font-size:14px;display:flex;
	    				.label{display:inline-block;width:100px;height:30px;line-height:30px;
	    					em{margin:0 5px;}
	    				}
	    				.input_item{width:240px;height:30px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
	    					&.small_input_item{width:60px;margin:0 5px;}
	    				}
	    			}
	    			.upload_box{border:1px solid #eee;width:80px;height:80px;position:relative;border-radius:5px;cursor:pointer;background:#f7f7f7;
	    				&:before,&:after{content: '';display: block;width: 30px;height: 3px;background-color: #d2d2d2;position: absolute;top:50%; left: 50%;margin-top:-1.5px;margin-left:-15px;border-radius: 7px;}
	    				&:before{transform: rotate(90deg);}
	    				img{width:80px;height:80px;}
	    				.upload_input{position: absolute;width: 80px;height: 80px;left: 0;top: 0;opacity: 0;cursor: pointer;z-index: 800;}
	    			}
	    		}
	    		.btn_group_box{display:flex;justify-content: center;}
	    		.btn_group{display:flex;margin-top:16px;
					.btn{font-size: 15px;color: #fff;background: #f18e00;margin-right: 30px;width: 80px;height: 32px;padding: 0;line-height: 32px;margin-top: 5px;cursor:pointer;margin-left:0;
						&:nth-last-of-type(1){margin-right:0px;}
					}
		    	}
		    }
		    .rule_module{
		    	.title{height: 30px;line-height: 30px;color: #fff;font-size: 16px;border-radius: 30px;background: #f7c976;margin: 0 auto;margin-bottom: 20px;width: 110px;text-align: center;padding:0;}
		    	.rule_box{
		    		.rule_content{margin-bottom:20px;
		    			.sub_title{color: #f00;font-size: 16px;margin-bottom: 10px;}
		    			.rule_item{color: #333;font-size: 14px;}
		    		}
		    	}
		    	.btn_group_box{display:block;
		    		.btn_group{display:flex;
		    			.btn{margin:0 auto;background:#f7c976;border-radius:30px;}
		    		}
		    	}
		    }
		}
	}
	.tab_page{text-align:right;margin-top:20px;
        span{height:25px;width:25px;display:inline-block;border:1px solid #ddd;line-height:25px;text-align:center;color:#333;font-size:12px;cursor:pointer;margin:0 5px;
            &:hover,&.active{background:#f18e00;border-color:#f18e00;color:#fff;}
        }
        i{height:25px;width:25px;display:inline-block;background:url(/img/back_icon.png) center no-repeat;vertical-align:middle;cursor:pointer;
            &.page_bks{background-image:url(/img/backs_icon.png)}
            &.page_fd{background-image:url(/img/next_icon.png)}
            &.page_fds{background-image:url(/img/nexts_icon.png)}
        }
    }
}




//手机端模板设置
.wapTemplateContainer{
	//模板设置
	.wapCmsContainer{margin: 20px;padding: 20px;background:#fff;border-radius:20px;
		.table_con{
			.tab_tit{
				&.tab_check_tit{
					span{position:relative;
						&:nth-of-type(2){width:34%;}
						&:nth-last-child(1){width:16%;}
						&.template_name{padding-right:50px;box-sizing: border-box;}
						.edit_icon{display:inline-block;width:25px;height:21px;background:url(/img/wap_index/edit_white.png) no-repeat center;background-size:25px 21px;
							position:absolute;right:15px;top:50%;transform: translateY(-50%);}
					}
				}
			}
		}
	}
	//新增模板弹框
	.add_template_module{
		.input_box{display:flex;align-items: center;justify-content: center;
			.label{color:#333;font-size:14px;padding-left:12px;position: relative;
				em{position: absolute;left:0;top:50%;transform: translateY(-50%);font-size:16px;color:#f00;height:20px;}
			}
			.input_item{display:flex;align-items: center;justify-content: center;padding:0 10px;width: 330px;height: 40px;border: 1px solid #C3C3C3;border-radius: 8px;
				.input{border:none;outline: none;width:300px;
					&::-webkit-input-placeholder {color:#999;}
				}
				.count{color:#999;font-size:14px;}
			}
		}
	}
}
//手机端模板设置详情页面
.wapCmsDetail{background:#fff;
	.detailContainer{display:flex;justify-content: space-between;height:900px;
		//模板左侧组件
		.detail_left{width:359px;height:100%;background:#fff;border: 1px solid #EAEAEA;padding-top:20px;box-sizing:border-box;
			.module_container{
				.module_title{display:flex;align-items: center;margin-bottom:30px;padding-left:20px;
					.icon{width:12px;height:8px;margin-right:5px;}
					.title{color:#333;font-size:16px;}
				}
				.module_box{display:flex;align-items: center;flex-wrap: wrap;
					.module_item{width:33.33%;display:flex;flex-direction: column;align-items: center;justify-content: center;margin-bottom:40px;
						.icon{margin-bottom:8px;}
						.title{font-size:14px;color:#333;}
					}
				}
			}
		}
		//模板页面展示
		.detail_middle{height:100%;box-sizing:border-box;
			.sort_button_group{display: flex;align-items: center;justify-content: center;margin-top:20px;margin-left:170px;margin-right:84px;}
			.mobile_container{position:relative;z-index:10;height:822px;display: flex;flex-direction: column;align-items:center;
				.mobile_bg{width:446px;height:822px;position:absolute;top:0;left:135px;z-index:-1}
				.top_head{width:375px;margin-top:60px;margin-bottom:10px;margin-left:85px;
					.input_box{padding:0 12px;display:flex;align-items:center;justify-content: center;;
						.input{flex:1;height:30px;border-radius:15px;background:#fff;margin-right:10px;}
						.kefu_icon{width:28px;height:26.5px;}
						.qian_icon{width:26px;height:30px;margin-left:10px;}
					}
				}
				.mobile_content{width:375px;height:700px;border-bottom-left-radius: 29px;border-bottom-right-radius: 29px;overflow: auto;padding-left:170px;padding-right:84px;
					.mobile_inner{height:auto;min-height:100%;background:#f7f7f7;border-bottom-left-radius: 29px;border-bottom-right-radius: 29px;}
					.module_item{background:#f7f7f7;opacity:0.5;position:relative;
						&.active_module_item{opacity:1;border:3px solid #F18E00;}
						.del_button{width:48px;height:48px;background:#f9f9f9;border:1px solid #ebebeb;display:flex;align-items: center;justify-content: center;cursor:pointer;
							position: absolute;right:-86px;top:50%;margin-top:-24px;
							&::before{content:'';display:block;position:absolute;left:-10px;width:0;height:0;border-right:10px solid #f9f9f9;border-top:10px solid transparent; border-bottom:10px solid transparent;}
							.del_icon{width:19px;height:18px;}
						}
						.module_info{width:130px;height:50px;background:#f9f9f9;border: 1px solid #EBEBEB;padding:0 5px;
							display:flex;flex-direction:column;align-items: center;justify-content: center;color:#333;font-size:16px;opacity: 0.8;
							position: absolute;left:-172px;top:50%;transform: translateY(-50%);
							&::after{content:'';display:block;position:absolute;right:-10px;width:0;height:0;border-left:10px solid #f9f9f9;border-top:10px solid transparent; border-bottom:10px solid transparent;}
							.title{width:100%;height:24px;display:block;text-align: center;}
							&.module_info_active{opacity: 1;
								input{border:none;outline: none;color:#333;width:100%;text-align:center;font-size:16px;height:24px;}
							}
						}
					}
				}
			}
			
			//轮播图
			.wap_slider{position:relative;display:flex;align-items:center;justify-content: center;
				.wap_slider_body{position: relative;border-radius:10px;overflow:hidden;width:351px;height:117px;
					.slider_main{position:relative;overflow:hidden;
						li{float: left;cursor:pointer;
							img{width:351px;max-height:117px;height:auto;object-fit: contain;}
						}
					}
				}
				.current_index{width:40px;height:19px;line-height:19px;color:#fff;font-size:10px;background:url(/img/wap_index/point.png) no-repeat center;background-size:40px 19px;position: absolute;left:5px;bottom:5px;display: flex;align-items: center;justify-content: center;}
			}

			//顶部标签view
			.top_tag_view{display:flex;align-items: center;justify-content: space-between;height:30px;padding:0 10px;background:#FB927E;
				.top_tag_item{display:flex;align-items: center;
					.tag_icon{margin-right:5px;width:12px;height:12px;object-fit: contain;}
					.tag_name{color:#fff;font-size:12px;}
				}
			}

			//频道导航
			.channel_nav_view{width:351px;margin:0 auto;border-radius:10px;display:flex;align-items: center;flex-wrap:wrap;padding:10px 0;background:#fff;
				.channel_nav_item{display:flex;flex-direction: column;align-items: center;width:20%;
					.nav_icon{width:45px;height:45px;object-fit:contain;margin-bottom:5px;}
					.nav_name{color:#333;font-size:12px;}
				}
			}
			
			//广告
			.adv_view{display:flex;flex-direction: column;align-items:center;justify-content: center;
				.adv_image{width:351px;height:auto;object-fit: contain;}
			}

			//专区
			.special_area_view{display:flex;align-items:center;justify-content: center;
				.area_module{display:flex;align-items: center;width:351px;
					img{object-fit: contain;border-radius: 4px;margin-right:10px;
						&:last-child{margin-right:0;}
					}
				}
				.area_module_two{
					img{width:170px;height:74px;}
				}
				.area_module_three{
					img{width: 110px;height: 109px;}
				}
				.area_module_four{
					img{width: 80px;height: 111px;}
				}
			}	

			//秒杀
			.seckill_view{width:351px;margin:0 auto;border-radius:6px;
				&::-webkit-scrollbar{display:block;}
				.seckill_head{height:47px;border-top-left-radius: 10px;border-top-right-radius: 10px;display: flex;align-items: center;padding-left:132px;position: relative;z-index:2;
					.bg_image{position:absolute;left:0;top:0;width:100%;height:100%;object-fit: cover;z-index:-1;}
					.seckill_state{height: 20px;border-radius:10px;font-size:12px;overflow:hidden;display:flex;align-items:center;
						.text{color:#fff;height:20px;padding:0 4px;line-height: 20px;}
						.time{padding:0 4px;}
						&.green{border: 1px solid #17A539;
							.text{background: linear-gradient(-45deg, #17A539, #17A575);}
							.time{color:#17A639;}
						}
						&.red{border: 1px solid #FF2A22;
							.text{background: linear-gradient(-45deg, #FF2A22, #FF9F22);}
							.time{color:#FF2A22;}
						}
						&.gray{background:#B4B4B4;width:72px;justify-content: center;line-height: 20px;color:#fff;}
					}
				}
				.scroll_view{overflow-x:auto;
					&::-webkit-scrollbar{width:6px;background:#ccc;display:block;height:4px;}
				}
				.seckill_goods_list{background:#fff;
					.clearfix{clear:both;height:0px;overflow:hidden}
				}
				.seckill_goods_item{position: relative;padding:10px 8px;display:flex;flex-direction: column;width:100%;box-sizing: border-box;align-items:center;
					.goods_flag{position:absolute;top:10px;left:0;z-index:10;max-width:32px;max-height:35px;}
					.goods_image_box{position: relative;margin-bottom:10px;border-radius:10px;
						.goods_image{width:80px;height:80px;object-fit: contain;display:block;border-radius:10px;}
						.empty_image{width:80px;height:80px;position: absolute;top:0;left:0;z-index:10;object-fit: contain;border-radius:10px;}
						.video_icon{position: absolute;top:50%;left:50%;width:24px;height:24px;z-index:20;transform: translate(-50%,-50%);}
					}
					
					.goods_btm_box{}
					.goods_name{text-align:center;color:#333;font-size:13px;margin-bottom:10px;width:100px;}
					.goods_price{display:flex;flex-direction: column;position: relative;text-align:center;
						.activity_price{color:#FD3B31;font-size:14px;text-align: center;
							.unit{font-size:10px;}
						}
						.origin_price{color:#B6B7BB;font-size:10px;text-decoration: line-through;text-align: center;}
					}
					.cart_icon{width:22px;height:22px;position: absolute;right:0;top:50%;margin-top:-13px;background:url('/img/wap_index/gwc.png') no-repeat center;background-size:22px 22px;}
					&:last-child{margin-right:0;}
				}
			}

			//限时限量
			.limit_view{display:flex;width:351px;margin:0 auto;padding:10px;box-sizing:border-box;border-radius:6px;
				.limit_item{
					&.hide{display:none;}
				}
				.limit_head{display:flex;align-items: center;font-size:16px;color:#333;font-weight:900;margin-bottom:15px;}

				.end_container{
					&.hide{display:none;}
				}
				.limit_num_box{height:20px;line-height:20px;padding:0 8px;color:#fff;text-align:center;font-size:12px;border-radius:10px;
					&.red_btn{background:#fd3b31;}
					&.grayBtn{background:rgba(0, 0, 0, 0.3);}
				}
				
				//两个一排
				&.limit_view_column{background:#fff;padding:0px;border-radius:10px;display:flex;justify-content: space-between;
					.limit_item{width:170px;height:170px;position: relative;padding:10px;box-sizing:border-box;}
					.limit_head{
						.title{margin-right:10px;}
					}
					.limit_item_num{border-radius:10px;background: linear-gradient(-56deg, rgba(255, 235, 201, 1) 0%, rgba(255, 205, 177, 1) 100%);}
					.limit_item_time{border-radius:10px;background: linear-gradient(16deg, rgba(255, 197, 197, 1) 0%, rgba(255, 217, 192, 1) 100%);}
					.goods_info_column{
						.goods_info{
							.goods_name{font-size:15px;color:#333;width:100%;margin-bottom:10px;}
							.goods_describe{font-size:12px;color:#333;width:80px;margin-bottom:15px;}
							.goods_price_box{display:flex;align-items: center;justify-content: space-between;position: absolute;left: 10px;right: 10px;bottom: 10px;}
							.goods_price{display:flex;flex-direction:column;
								.activity_price{color:#FD3B31;font-size:16px;
									em{font-size:10px;}
								}
								.origin_price{color:#888;font-size:10px;text-decoration: line-through;}
							}
							
						}
						.goods_image{width:60px;height:60px;object-fit: contain;}
					}
				}

				//一个一排
				&.limit_view_row{background: linear-gradient(-56deg, #FFEBC9 0%, #FFCDB1 100%);padding:10px;border-radius:6px;
					.limit_item{width:100%;}
					.limit_head{justify-content: space-between;margin-bottom:20px;}
					.goods_info_row{position: relative;display:flex;align-items:center;
						.goods_image{width:80px;height:80px;object-fit: contain;margin-right:20px;}
						.goods_info{width:220px;
							.goods_name{font-size:15px;color:#333;width:100%;margin-bottom:10px;}
							.goods_describe{font-size:12px;color:#444;width:100%;margin-bottom:20px;}
							.goods_price{display:flex;flex-direction:column;
								.activity_price{color:#FD3B31;font-size:16px;
									em{font-size:10px;}
								}
								.origin_price{color:#888;font-size:10px;text-decoration: line-through;}
							}
						}
					}
				}
			}

			//商品列表
			.goods_list_view{

				//九宫格 | 常规 | 左右横滑布局商品样式
				.goods_item{position:relative;background:#fff;border-radius:10px;padding:10px 5px;margin-bottom:6px;
					
					.goods_image_box{position: relative;margin:0 auto 10px;
						.goods_flag{position:absolute;top:0px;left:0;z-index:10;object-fit: contain;}
						.goods_image{display:block;object-fit: contain;width:100%;height:100%;border-radius:10px;}
						.empty_image{position: absolute;top:0;left:0;z-index:10;object-fit: contain;width:100%;height:100%;border-radius:10px;}
						.video_icon{position: absolute;top:50%;left:50%;width:24px;height:24px;z-index:20;transform: translate(-50%,-50%);}
					}
					
					.goods_name{font-size:13px;color:#333;margin-bottom:10px;}
					.goods_bottom_box{display:flex;justify-content: space-between;align-items: center;}
					.goods_price_box{display:flex;flex-direction: column;}
					.goods_price{color:#FD3B31;font-size:14px;
						.unit{font-size:10px;}
					}
					.origin_price{color:#999;font-size:10px;text-decoration: line-through;}
					.cart_icon{width:20px;height:20px;}	
				}

				//九宫格
				&.speed_dial_list{background:#ffeed3;padding:10px 10px 0;display:flex;flex-wrap: wrap;border-radius:10px;width:351px;margin:0 auto;box-sizing:border-box;
					.goods_item{width:97px;margin-right:5px;display:flex;flex-direction: column;align-items: center;
						.goods_image_box{width:87px;height:87px;}
						.goods_info_box{display:flex;flex-direction: column;align-items: center;}
						.goods_name{width:97px;text-align: center;}
						.goods_price_box{align-items: center;}
						&:nth-of-type(3n){margin-right: 0;}
					}
					.goods_flag{width:25px;height:25px;}
				}
				//常规
				&.general_list{display:flex;flex-wrap: wrap;justify-content: space-between;width:351px;margin:0 auto;box-sizing:border-box;
					.goods_item{width:170px;background:#fff;border-radius:6px;padding:10px;margin-bottom:10px;box-sizing:border-box;display:flex;flex-direction: column;justify-content: space-between;}
					.goods_image_box{width:140px;height:140px;
						.goods_flag{width:40px;height:40px;}
					}
					.cart_icon{width:26px;height:26px;}	
				}
				//左右侧滑
				&.sliding_around_list{width:351px;margin:0 auto;box-sizing:border-box;
					.goods_item{background:#fff;border-radius:6px;padding:10px;margin-bottom:0;display:flex;flex-direction: column;align-items: center;
						.goods_image_box{width:80px;height:80px;margin:0 auto 10px;
							.goods_flag{width:25px;height:25px;}
						}
						.goods_info_box{}
						.goods_name{width:90px;text-align:center;margin-bottom:10px;}
						.goods_bottom_box{justify-content: center;}
						.goods_price_box{align-items: center;}
						.cart_icon{width:18px;height:18px;}	
					}
				}
				//商品列表
				&.goods_list{background:#fff;border-radius:10px;padding:10px 5px;position:relative;display:flex;margin:0 auto;box-sizing:border-box;width:351px;padding:10px;
					.goods_list_item{margin-bottom:20px;display:flex;width:100%;display:flex;align-items: center;position: relative;
						&:last-child{margin-bottom:0;}
						.goods_flag{position:absolute;top:0px;left:0px;z-index:10;width:30px;height:30px;}
						.goods_image_box{position: relative;width:100px;height:100px;margin-right:10px;flex-shrink: 0;border-radius:10px;
							.goods_image{display:block;object-fit: contain;width:100%;height:100%;border-radius:10px;}
							.empty_image{position: absolute;top:0;left:0;z-index:10;object-fit: contain;width:100%;height:100%;border-radius:10px;}
							.video_icon{position: absolute;top:50%;left:50%;width:24px;height:24px;z-index:20;transform: translate(-50%,-50%);}
						}
						.goods_info_box{flex:1;position: relative;width:220px;}
						.goods_name{font-size:13px;color:#333;margin-bottom:10px;width:220px;}
						.goods_number{font-size:12px;color:#666;margin-bottom:10px;}
						.goods_flag_box{display:flex;align-items: center;
							.goods_flag_item{border:1px solid rgba(255,48,42,1);border-radius:3px;margin-right:10px;font-size:10px;color:#FF302A;padding:2px 4px;
								&.green{border: 1px solid #3B8D4F;color:#3B8D4F;}
								&:last-child{margin-right:0;}
							}
						}
						.goods_price_box{display:flex;align-items:center;margin-top:15px;
							.price_box{color:#FD3B31;font-size:15px;margin-right:10px;
								.unit{font-size:12px;}
							}
							.old_price{color:#666;font-size:12px;}
						}
						
						.cart_icon{width:32px;height:32px;position: absolute;right:0;bottom:0;}	
					}
				}
			}

			// 专区商品列表
			.area_goods_list_view{
				&.speed_dial_list{
					background:#f7f7f7;padding:0;
					.goods_item{
						width: 113px;
						height: 136px;
						box-sizing: border-box;
						padding: 6px 5px;
						.goods_image_box{width: 100px;height: 56px;margin:0 auto 5px;
							.goods_image{object-fit: contain;}
						}
						.goods_info_box{
							.goods_name{
								margin-bottom:2px;
								color:#333;
								font-size:12px;
								font-weight:900;
							}
						}
						.goods_price_box{
							color:#fd3b31;
							.goods_price{
								display:flex;
								height:28px;
								align-items: center;
								.price{font-size:22px;font-weight:900;}
								.unit{
									display: flex;
									flex-direction: column;
									text-align: center;
									margin-left: 5px;
									transform: scale(0.55);
									font-weight:900;
									.split_line{
										width: 12px;
										height: 1px;
										background: #FD3B31;
										margin: 2px 0;
										transform: rotate(10deg);
									}
								}
							}
							.origin_price{
								font-size:10px;
								color:#fd3b31;
								transform: scale(0.8);
							}
						}
					}
					.goods_flag{width:20px;height:20px;}
				}
			}

			//Tab标签
			.tab_tag_view{
				.tab_head{display:flex;align-items: center;justify-content: space-between;position: sticky;top:0;z-index:100;background:#f7f7f7;
					.tab_item{height:58px;display:flex;flex-direction:column;align-items:center;position: relative;padding-top:10px;
						&.two{width:50%;}
						&.three{width:33.33%;}
						&.four{width:25%;}
						.tab_name{color:#333;font-size:16px;margin-bottom:5px;font-weight: 900;}
						.tab_desc{color:#666;font-size:10px;height:20px;padding:0 12px;line-height:20px;text-align: center;border-radius:10px;}
						&.active{
							.tab_name{color:#f18e00;}
							.tab_desc{color:#fff;background:#FFAE3A;}
						}
						.line{width:1px;height:50px;display:block;position: absolute;right:0;top:50%;transform: translateY(-50%);background-color: #eee;}
					}
				}
				.goods_list_view{
					&.speed_dial_list{background:#f7f7f7;}
				}
			}
			.ellipsis_clamp{overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;}
			.ellipsis{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
		}
	
		//模板右侧设置
		.detail_right{width:500px;background:#fff;border:1px solid #eaeaea;padding:20px;height:100%;box-sizing:border-box;
			select{padding-right:25px;background:#fff url('/img/index/my_arrow.png') no-repeat 135px center;}
			.head_con{display:flex;align-items: center;justify-content: space-between;margin-bottom:30px;
				.title{color:#333;font-size:18px;}
				.close_btn{width:15px;height:15px;background:url('/img/wap_index/sz_gb.png') no-repeat center;background-size: 15px 15px;cursor: pointer;}
			}
			.scroll_view{width:100%;height:800px;padding-right:10px;overflow-y: auto;
				&::-webkit-scrollbar{width:4px;background:#ccc;display:block;}
			}
			.publish_time{font-size:16px;color:#333;margin-bottom:40px;}
			.detail_module_content{margin-bottom:50px;}
			.module_margin{margin-bottom:100px;color:#333;font-size:16px;
				.radio_box{display:flex;
					.radio_item{display: flex;align-items: center;margin-right:66px;
						.icon{margin-right:10px;}
						&:last-child{margin-right:0;}
					}
				}
			}
			.module_button_group{width:500px;height:60px;display:flex;align-items: center;justify-content: center;position: fixed;bottom:0;background:#fff;z-index:100;
				.common_button{margin-right:55px;
					&:last-child{margin-right:0;}
				}
			}
			.module_title{margin-bottom:20px;
				.title{display:flex;align-items:center;
					&.require{
						&::before{content:'*';display: block;font-size:20px;color:#f00;height: 20px;padding-right: 2px;font-weight: 900;}
					}
				}
				.desc{color:#666;font-size:14px;margin-top:10px;line-height:24px;}
			}
			//自定义input框
			#my_input{display:flex;align-items:center;
				.label{font-size:14px;color:#333;margin-right:8px;display:flex;
					&.require{
						&::before{content:'*';display: block;font-size:20px;color:#f00;height: 20px;padding-right: 2px;font-weight: 900;}
					}
				}
				.input{border:none;outline:none;border:1px solid #eaeaea;border-radius: 4px;width:229px;height:30px;padding:0 10px;}
				.with_count_input{border:1px solid #eaeaea;border-radius: 4px;width:229px;height:30px;display:flex;align-items:center;
					.input{flex:1;padding:0 10px;border:none;}
					.count{padding-right:10px;color:#999;}
				}
			}
			
			//排序组件
			.sort_group{height:40px;display: flex;justify-content: flex-end;align-items: center;padding-right:10px;border-bottom:1px solid #eaeaea;
				.btn{width:26px;height:26px;margin-right:20px;
					&:last-child{margin-right: 0;}
				}
				.up_sort{background:url('/img/wap_index/up_un.png') no-repeat center;background-size:26px 26px;
					&:hover{background-image:url('/img/wap_index/up.png')}
				}
				.down_sort{background:url('/img/wap_index/down_un.png') no-repeat center;background-size:26px 26px;
					&:hover{background-image:url('/img/wap_index/down.png')}
				}
				.del_btn{background:url('/img/wap_index/close_un.png') no-repeat center;background-size:26px 26px;}
			}
			//图片上传
			.upload_image_box{position: relative;cursor:pointer;
				.uploadInput{position: absolute;width: 100%;height: 100%;opacity: 0;z-index:10;}
				.replace_image{
					.image{width:100%;height:100%;object-fit: contain;}
					.text{position: absolute;bottom:0;left:0;color:#fff;font-size:12px;background:rgba(0,0,0,0.4);height:20px;width:100%;text-align: center;line-height: 20px;}
				}
				.add_image{width:100%;height:100%;background:#f8f8f8;border:1px solid #eaeaea;display:flex;flex-direction: column;align-items: center;justify-content: center;font-size:14px;color:#f18e00;
					.add_icon{width:22px;height:22px;margin-bottom:10px;}
				}
			}
			//继续添加按钮
			.add_button{width:410px;height:52px;border: 1px solid #F18E00;border-radius: 10px;display:flex;align-items: center;justify-content: center;font-size:16px;color:#f18e00;margin-top:20px;cursor:pointer;
				.add_icon{width:14px;height:14px;background:url('/img/wap_index/add.png') no-repeat center;background-size:14px 14px;margin-right:10px;}
			}
			//跳转内容btn
			.link_btn_box{display:flex;align-items:center;
				.label{font-size:14px;color:#333;margin-right:8px;}
				.link_btn{height: 30px;line-height:30px;border: 1px solid #EAEAEA;border-radius: 4px;color:#888;font-size:14px;padding:0 10px;padding-right:30px;box-sizing:border-box;cursor: pointer;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;position: relative;
					.link_clear_btn{width:30px;height:30px;position: absolute;right:0px;top:0;display:flex;align-items: center;justify-content: center;
						img{display:block;}
					}
				}
			}
			//商品表格
			.wap_goods_table{margin-top:30px;
				.con_search{margin-bottom:30px;display:flex;justify-content: center;
					.search_item{margin-right:20px;}
					select{width:122px;margin-right:10px;background:#fff url('/img/index/my_arrow.png') no-repeat 100px center;border:none;outline: none;height:40px;border:1px solid #c3c3c3;border-radius:6px;text-align:center;margin-right:20px;padding:0 10px;}
					.input_item{width:333px;height:40px;border:1px solid #c3c3c3;}
					.btn_group{margin-top: 0;width:auto;}
				}
				.table_con{width:957px;
					.tab_tit{
						span{width:12%;display:flex;align-items:center;justify-content: center;
							&:nth-of-type(1){width:6%;}
							&:nth-of-type(5){width:45%;}
							.goods_image{width:40px;height:40px;}
						}
					}
				}
			}
			//跳转内容弹框
			.link_module{margin-top:30px;
				.module_content{display:flex;flex-direction: column;;align-items: center;margin-bottom:0;}
				.tab_box{display: flex;width:544px;height:50px;border: 1px solid #D7D7D7;border-radius:25px;overflow:hidden;
					.tab_item{width:25%;height:50px;line-height: 50px;border-right:1px solid #D7D7D7;text-align: center;color:#333;font-size:16px;
						&.active{background:#f18e00;color:#fff;}
						&:last-child{border-right:none;}
					}
				}
				.tab_content{
					select{border:none;outline: none;height:40px;border:1px solid #c3c3c3;border-radius:6px;text-align:center;margin-right:20px;padding:0 10px;}
					.input_box{display:flex;align-items: center;color:#333;font-size:16px;margin-top:60px;margin-bottom:80px;
						.input{width:400px;height:40px;border:none;outline:none;border:1px solid #eaeaea;border-radius:10px;padding:0 10px;}
					}
					.select_box{margin-top:60px;margin-bottom:80px;
						select{width:150px;}
					}
				}
			}
			
			//模块图片列表组件
			.module_image_item{width: 410px;border: 1px solid #eaeaea;border-radius: 10px;margin-bottom:20px;
				.banner_item_inner{display: flex;flex-direction: column;align-items: center;padding: 10px 20px 30px;
					.image_box{margin-bottom: 20px;}
				}
			}
			
			//布局样式
			.layout_radio{display:flex;flex-wrap:wrap;align-items: center;
				.radio_item{display:flex;align-items: center;color:#333;font-size:16px;width:180px;margin-bottom:20px;
					.radio_icon{margin-right:10px;cursor: pointer;}
				}
			}

			//商品录入头部
			.goods_import_head{display:flex;align-items: center;justify-content: space-between;font-size:14px;width:410px;margin-bottom:20px;
				.count{color:#666;
					em{color:#f18e00;}
				}
				.clear_goods{display:flex;align-items: center;font-size:16px;color:#f00;cursor:pointer;
					.clear_icon{width:19px;height:18px;margin-right:10px;}
				}
			}

			//右侧商品列表
			.seckill_goods_item{width:410px;border:1px solid #eaeaea;border-radius: 10px;margin-bottom:20px;
				.common_goods_item{padding:20px 15px;
					.goods_info_box{display:flex;padding:20px 15px;
						.image_box{margin-right:20px;}
						.goods_info{
							.info_item{display:flex;align-items:center;margin-bottom:20px;font-size:14px;color:#333;
								&:last-child{margin-bottom: 0;}
								.info{flex:1}
							}
						}
					}
					.goods_flag_box{position: relative;
						.clear_btn{width:13px;height:13px;position: absolute;top:-34px;right:-7px;z-index:100;background:url('/img/wap_index/tab_gb.png') no-repeat center;background-size:13px 13px;}
					}
					.form_box{margin-top:20px;
						.input_item{display:flex;align-items: center;margin-bottom:20px;font-size:14px;
							.label{color:#333;display:flex;margin-right:10px;width:83px;
								&.old_price_label{
									width: 70px;
									text-align: right;
									display: flex;
									justify-content: flex-end;
								}
								&.require{
									&::before{content:'*';display: block;font-size:20px;color:#f00;height: 20px;padding-right: 2px;font-weight: 900;}
								}
							}
							&:last-child{margin-bottom: 0;}
							.goods_unit{padding-left:5px;}
						}
					}
				}	
			}

			//轮播图
			#banner_module{
				.banner_item{width:410px;border:1px solid #eaeaea;border-radius:10px;
					.banner_item_inner{display:flex;flex-direction: column;align-items: center;padding:10px 30px 20px;
						.image_box{margin-bottom:20px;}
					}
				}
				
				.banner_speed{margin-top:30px;
					.input_box{margin-top:20px;display:flex;align-items: center;font-size:16px;color:#333;
						.input{width: 120px;height: 34px;background: #FFFFFF;border:none;outline:none;border: 1px solid #EAEAEA;border-radius: 6px;margin-right:15px;padding-left:10px;}
					}
				}
			}
			
			//顶部标签
			#top_tag_module{
				.top_tag_item_box{border:1px solid #eaeaea;border-radius:10px;margin-bottom:20px;
					.top_tag_item{display:flex;align-items: center;padding:20px;
						.image_box{margin-right:20px;}
					}
				}
			}

			//频道导航
			#channel_nav_module{
				.channel_list{border:1px solid #eaeaea;border-radius:10px;margin-bottom:20px;
					.channel_item_content{display:flex;align-items: center;padding:20px;
						.image_box{margin-right:20px;}
						.input_box{height:90px;display:flex;flex-direction: column;justify-content: space-between;}
					}
				}
			}

			//广告
			#adv_module{
				.adv_container{width:410px;border:1px solid #eaeaea;border-radius:6px;display:flex;flex-direction: column;align-items: center;padding:10px 30px 20px;
					.image_box{margin-bottom:20px;}
				}
			}

			//专区
			#special_area_module{
				.layout_box{margin-bottom:20px;
					.radio_group{display:flex;align-items: center;justify-content: space-between;
						.radio_item{display:flex;align-items: center;color:#333;font-size:16px;
							.radio_icon{margin-right:10px;cursor: pointer;}
						}
					}
				}
			}

			//整点秒杀模块
			#seckill_module{
				.time_box{margin-bottom:30px;}
				.goods_container{}
			}

			//限时限量
			#limit_module{
				.limit_type_box{margin-bottom: 30px;}
				.radio_group{display:flex;align-items: center;
					.radio_item{display:flex;align-items:center;margin-right:50px;
						.radio_icon{margin-right:10px;}
					}
				}
				.time_box{margin-bottom: 30px;display:flex;align-items: center;
					.label{margin-right:10px;}
				}
				.limit_container{
					.limit_item{margin-bottom:50px;
						&:last-child{margin-bottom: 0;}
					}
				}
				.goods_info{
					.title{margin-bottom:20px;color:#333;font-size:14px;
						.tip{font-size:12px;color:#666;}
					}
				}
				.goods_item_box{width:410px;border:1px solid #eaeaea;border-radius:10px;
					.goods_item_head{height:42px;display:flex;align-items: center;justify-content: flex-end;padding-right:20px;color:#f18e00;font-size: 16px;border-bottom:1px solid #eaeaea;}
					.goods_item{padding:20px 15px;
						.goods_info_box{display:flex;padding:20px 15px;
							.image_box{margin-right:20px;}
							.goods_info{
								.info_item{display:flex;align-items:center;margin-bottom:20px;font-size:14px;color:#333;
									&:last-child{margin-bottom: 0;}
								}
							}
						}
						.form_box{margin-top:20px;
							.input_item{display:flex;align-items: center;margin-bottom:20px;font-size:14px;
								.label{color:#333;display:flex;margin-right:10px;width:83px;
									&.require{
										&::before{content:'*';display: block;font-size:20px;color:#f00;height: 20px;padding-right: 2px;font-weight: 900;}
									}
								}
								&:last-child{margin-bottom: 0;}
								.goods_unit{padding-left:5px;}
							}
						}
					}
				}
			}

			//商品列表
			#goods_list_module{
				.layout_box{margin-bottom:10px;}
			}

			//Tab标签
			#tab_tag_module{
				.layout_radio{
					.radio_item{width:140px;}
				}
				.tab_container{
					.tag_group_box{display:flex;align-items: center;margin-bottom:30px;}
					.tag_group{display: inline-flex;align-items: center;height:46px;border-radius:23px;background:#f7f7f7;
						.tag_item{width:92px;height:46px;text-align: center;line-height: 46px;color:#333;font-size:16px;position: relative;border-radius:24px;cursor: pointer;
							.del_btn{position: absolute;top:-6px;right:5px;width:13px;height:13px;display:none;background:url('/img/wap_index/tab_gb.png') no-repeat center;background-size:13px 13px;cursor: pointer;}
							&.active{background:#FFE3BB;color:#F18E00;}
							&:hover{background:#FFE3BB;color:#F18E00;
								.del_btn{display:block;}
							}
						}	
					}
					.tab_add_icon{width:42px;height:42px;margin-left:15px;}
				}
				.goods_container{
					.tab_tag_item{
						.from_box{margin-bottom: 20px;
							.input_item{margin-bottom:20px;}
						}
					}
				}
			}
		}
	}
	
	//日期框
	.setTimeContainer{display:flex;margin-top:0;margin-bottom:20px;height:36px;line-height:36px;
		label{margin-right:10px;}
		.setTimebox{width: 100px;height: 30px;line-height: 30px;float: left;background-color: #f0f0f0;border: 1px solid #ddd;font-size: 12px;color: #333;padding: 0 20px;padding-top: 3px;background: url(../img/icon_down_up.png) center no-repeat;
			background-size: 9px 11px;background-position: 120px center;
			&.leftDate{
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
			&.rightTime{
				border-left: none;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}

	//swiper轮播图
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
  
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	  }


	  //日期框
	  .setTimebox {
		width: 140px;
		height: 30px;
		line-height: 30px;
	  
		background-color: #f0f0f0;
		border: 1px solid #ddd;
		font-size: 12px;
		color: #333;
		padding: 0 20px;
		padding-top: 3px;
		background: url("/img/icon_down_up.png") center no-repeat;
		background-size: 9px 11px;
		background-position: 160px center;
	  }
	  .floatR {
		float: none;
	  }
	  .setTimebox.leftDate {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	  }
	  .setTimebox.rightTime {
		border-left: none;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	  }

	// 商品上线时间日期框
	.goods_time_box{
		display:flex;
		align-items: center;
		.label{
			font-size:14px;
			display:flex;
			align-items: center;
			em{
				font-size: 20px;
				color: #f00;
				height: 20px;
				padding-right: 2px;
				font-weight: 900;
			}
		}
		.setTimebox{
			width: 120px;
			padding: 0 10px;
			background-position: 128px center;
		}
	}

	// 商品显示数量输入框
	.goods_num_input{
		display:flex;
		align-items: center;
		.label{
			font-size: 20px;
			color: #f00;
			height: 20px;
			padding-right: 2px;
			font-weight: 900;}
		.input{
			border:none;
			outline: none;
			border-radius:5px;
			border:1px solid #bbb;
			padding-left:5px;
			margin:0 5px;
		}
	}

	//手机模块空页面
	.module_empty{display:flex;align-items:center;justify-content: center;color:#444;font-size:16px;background:#f7f7f7;height:137px;cursor: pointer;}
}


// 首页商品提报
.goodsTemplateContainer{
	.goodsCmsContainer{
		.search_item{
			.label{margin-right:10px;}
			.input_item{width:100px;height:30px;margin:5px;outline:none;border:none;border:1px solid #ccc;border-radius:4px;padding-left:10px;box-sizing:border-box;
				&.select{width:120px;padding-right:25px;background:#fff url('/img/index/my_arrow.png') no-repeat 100px center;}
			}
		}
		//日期框
		.setTimeContainer{display:flex;margin-top:0;height:36px;line-height:36px;
			.setTimebox{width: 100px;height: 30px;line-height: 30px;float: left;background-color: #f0f0f0;border: 1px solid #ddd;font-size: 12px;color: #333;padding: 0 20px;padding-top: 3px;background: url(../img/icon_down_up.png) center no-repeat;
				background-size: 9px 11px;background-position: 120px center;
				&.leftDate{
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
				}
				&.rightTime{
					border-left: none;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}
		}
		.table_con{
			.tab_tit{
				&.tab_check_tit{
					span{width:200px;
						&:nth-of-type(1){width:100px;}
						&:nth-of-type(2){width:160px;}
					}
				}
			}
		}
	}
}

//分类页管理
.gcContainer{padding:20px;
	.gcItemContainer{width:482px;height:100%;background:#fff;border-radius: 20px;margin-right:20px;padding-bottom:10px;position: relative;
		.top_title{color:#333;font-size:16px;height:50px;line-height:50px;padding-left:10px;}
		.scroll_view{overflow-y: auto;border-bottom-left-radius: 20px;border-bottom-right-radius:20px;}
		.rankContainer{border-radius:20px;border:1px solid #eaeaea;padding:15px 12px 20px;margin:0 10px 10px;min-height: 240px;box-sizing: border-box;
			.with_edit_title{margin-bottom:20px;}
			.entry_image_box{
				.rank_title{color:#666;font-size:14px;margin-bottom:10px;display:block;}
				.image_box{position:relative;overflow:hidden;
					.rank_image{width:100%;border-radius:10px;display:block;}
					.upload_time{position: absolute;bottom:0;right:0;padding:6px;color:#666;font-size:12px;background:rgba(255,255,255,0.8)}
				}
			}
		}
		.gcSearchContainer{padding: 15px 12px;margin: 0 10px 10px;border: 1px solid #eaeaea;border-radius: 20px;
			.with_edit_title{margin-bottom:20px;}
			.search_list{
				.sec_item{margin-bottom:10px;
					.sec_name{display:flex;align-items:center;font-size:16px;color:#333;margin-bottom:15px;
						.icon{width:11px;height:13px;background:#f18e00;margin-right:5px;background:url(/img/gc_page/fenlei.png) no-repeat center;background-size:11px 13px;display:block;}
					}
					&:last-child{margin-bottom:0;}
				}
				.search_words_list{display:flex;align-items: center;flex-wrap: wrap;
					.search_words{margin-right:10px;margin-bottom:10px;}
				}
			}
		}
		&:last-child{margin-right:0;}
	}
	
	//修改swiper样式
	.swiper-pagination-bullet-active{ background:#f18e00 }
	.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{color:#f18e00;}
	.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{color:#f18e00;}
}

//分类排行入口管理页
.gcRankManage{
	.common_sub_title{padding:0 0 20px;}
	.detail_content{background:#fff;border-radius:20px;padding:20px 12px 30px;margin-bottom:10px;display:flex;flex-direction: column;
		.title{font-size:16px;color:#333;margin-bottom:30px;display:block;margin-bottom:20px;
			.upload_time{color:#f18e00;margin-left:20px;}
		}
		.tips{color:#666;font-size:14px;}
		.radio_group{display:flex;align-items: center;
			.radio_item{display:flex;align-items: center; margin-right:100px;
				.radio_icon{margin-right:5px;}
			}
		}

		.entry_image{width:414px;border-radius:10px;margin:30px 0;}

		.btn_group{width: 414px;display: flex;align-items: center;justify-content: center;}
	}

	//上传图片弹框
	.upload_image_module{
		.drag_area{width:414px;min-height:124px;border:1px dashed #dbdbdb;position:relative;border-radius:10px;
			.upload_image_box{
				.add_icon{top: 50%;position: absolute;left: 50%;transform: translate(-50%,-50%);}
			}
			.upload_image{width:100%;height:100%;object-fit: contain;border-radius:10px;}
			.uploader_tips{width:100%;height:100%;display:flex;flex-direction: column;align-items: center;justify-content: center;color:#999;font-size:14px;
				.add_icon{width:36px;height:36px;margin-bottom:20px;}
			}
			.upload_input{position: absolute;top:0;left:0;width:100%;height:100%;z-index:10;opacity:0;}
		}
	}
}

//分类排行搜索词管理页面
.gcSearchManage{
	.common_sub_title{padding:0 0 20px;}
	.detailContainer{
		.detail_content{background:#fff;border-radius:20px;padding:20px 10px 30px;margin-bottom:10px;
			.search_words_box{margin-top:30px;display: flex;align-items: center;flex-wrap: wrap;}
		}
	}
}

//搜索管理页面
.searchContainer{display:flex;
	.searchContent{width:482px;background:#fff;border-radius: 20px;margin-right:20px;
		.search_head{height:50px;display:flex;align-items: center;padding:0 10px;}
		.with_edit_title{width:100%;}
		.scroll_view{overflow-y: auto;border-bottom-left-radius: 20px;border-bottom-right-radius:20px;margin:0 20px;}
		.contentItem{width:438px;background:#fff;border:1px solid #eaeaea;border-radius:10px;padding:20px 10px;margin-bottom:10px;box-sizing: border-box;
			.title{font-size:16px;color:#333;}
			.search_words_box{display:flex;flex-wrap:wrap;margin-top:20px;}
		}
	}
}
//热门搜索词管理
.hotWordsManage{
	.common_sub_title{padding:0 0 20px;}
	.hot_words_item{background:#fff;padding:20px 10px;border-radius:10px;margin-bottom:10px;
		.search_words_box{display:flex;flex-wrap:wrap;margin-top:25px;}
	}
}
//搜索页-排行榜管理
.searchRankManage{
	.common_sub_title{padding:0 0 20px;}
	.content_item{background:#fff;border-radius:20px;padding:20px 10px 30px;margin-bottom:10px;
		.title{margin-bottom:20px;display: block;}
		.radio_group{display:flex;align-items: center;
			.radio_item{display:flex;align-items: center; margin-right:100px;
				.radio_icon{margin-right:5px;}
			}
		}
	}
}

//排行榜管理页
.rankContainer{
	.rankItemContainer{width: 392px;background: #FFFFFF;border-radius: 20px;padding-bottom:20px;
		.rank_item_title{height:50px;line-height:50px;padding:0 10px;}
		.scroll_view{overflow-y: auto;border-bottom-left-radius: 20px;border-bottom-right-radius:20px;padding:0 20px;}
		.rank_item_content{border: 1px solid #EAEAEA;border-radius: 20px;padding:20px 10px 35px;margin-bottom:10px;min-height:174px;box-sizing: border-box;}
	}
	//修改swiper样式
	.swiper-pagination-bullet-active{ background:#f18e00 }
}
//排行榜详情管理
.rankDetailManage{
	.common_sub_title{padding:0 0 20px;}
	.contentItem{background:#fff;border-radius:20px;padding:20px 10px;margin-bottom:10px;}
	.recommend_info_box{display:flex;align-items:center;
		.recommend_left{margin-right:120px;}
		.recommend_right{display:flex;flex-direction: column;padding-top:40px;}
	}
	.recommend_empty_box{width: 289px;height: 94px;background: #FFFFFF;border: 1px dashed #DBDBDB;border-radius: 10px;display: flex;align-items: center;justify-content: center;cursor:pointer;
		.add_icon{width:22px;height:22px;margin-right:10px;}
		.text{color:#999;font-size:14px;}
	}
	.goods_rank_box{
		.rank_title{
			.end_time{margin-left:45px;color:#f00;font-size:14px;}
		}
		.table_box{
			.table_con{
				.tab_tit{
					span{width:9%;
						&.goods_name{width:18%}
					}
				}
				.table_content{max-height:600px;overflow: auto;
					&::-webkit-scrollbar{width:4px;background:#ccc;display:block;}
				}
			}
		}
		.no_data{height: 60px;display: flex;align-items: center;justify-content: center;color: #999;}
	}
	//排行榜商品列表
	.rank_goods_table{width:800px;
		.con_search{justify-content: center;
			.search_item{margin-right:40px;}
		}
		.table_con{
			.tab_tit{
				&.tab_check_tit{
					span{width:9%;
						&.goods_name{width:18%}
					}
				}
			}
		}
	}
	//排名干预表格
	.rank_sort_module{
		.table_box{width:1200px;
			.table_con{
				.tab_tit{
					span{width:8%;
						em{padding-right:0;}
						&.goods_name{width:15%}
						&.goods_price{width:9%;}
						&:last-child{width:11%}
					}
				}
				.table_content{max-height:400px;overflow: auto;
					&::-webkit-scrollbar{width:4px;background:#ccc;display:block;}
				}
			}
		}
	}
}

//排行榜公共标题
.rank_title{font-size:16px;color:#333;margin-bottom:20px;display:flex;align-items:center;
	.title{margin-right:20px;display:block;}
	.tip{color:#888;font-size:14px;}
}
//排行榜商品信息
.rank_goods_item{display:flex;align-items: center;
	.goods_image_box{position: relative;width:70px;height:70px;border:1px solid #eaeaea;border-radius:10px;margin-right:20px;overflow: hidden;flex-shrink: 0;
		.goods_image{width:100%;height:100%;}
		.sort_icon{width:18px;height:18px;font-size:10px;background:#f18e00;border-radius: 50%;line-height: 18px;text-align:center;color:#fff;position: absolute;top:0;left:0;}
		.state_name{width:70px;height:20px;position: absolute;left:0;bottom:0;line-height: 20px;text-align: center;color:#fff;font-size: 12px;background:rgba(0,0,0,0.5);}
	}
	.goods_info{color:#333;font-size:14px;width:240px;
		.goods_name{margin-bottom:6px;width:100%;}
		.goods_id{margin-bottom:13px;}
	}
}




//编辑搜索词公共弹框
.edit_search_mod{
	.search_content{
		.textarea{width: 530px;height: 144px;background: #FFFFFF;border: 1px solid #EAEAEA;border-radius: 4px;margin-bottom:10px;padding:10px;}
		.tip_box{display:flex;flex-direction: column;
			.tip_item{color:#666;font-size:12px;margin-bottom:10px;}
		}
	}
}

//公共组件
.with_edit_title{display:flex;align-items:center;justify-content: space-between;
	.title{color:#333;font-size: 16px;margin-right:10px;}
	.show_state{font-size:14px;margin-left:10px;
		.state_circle{width:10px;height:10px;margin-right:4px;display:inline-block;border-radius:50%;}
		&.green{color:#02A509;
			.state_circle{background:#02A509;}
		}
		&.gray{color:#666;
			.state_circle{background:#999;}
		}
	}
	.edit_btn{color:#f18e00;font-size:16px;display:flex;align-items:center;cursor: pointer;margin-left:10px;
		.edit_icon{width:12px;height:14px;display:block;background:url(/img/gc_page/bianji.png) no-repeat center;background-size:12px 14px;margin-right:4px;}
	}
}

//搜索词
.search_words{padding:4px 10px;font-size:14px;color:#666;border: 1px solid #666666;border-radius: 15px;margin-right: 10px;margin-bottom: 10px;}

//空页面
.emptyContainer{color:#666;}

//日期框清除按钮
#calendarClear{display: none;}

//alert
.pub_mod{position:fixed;min-width:500px;background:#fff;left:50%;top:50%;border-radius:5px;transform:translate(-50%,-50%);margin-top:-68px;
    &:before{content:attr(data-tit);color:#f4a533;display:block;height:70px;line-height: 70px;border-bottom: 1px solid #ddd;font-size:18px;padding-left: 20px;}
    .mod_btn{margin:30px 0;text-align:center;
        .pub_btn{display:inline-block;height:34px;width:90px;line-height:34px;margin:0 10px;cursor:pointer;background:#f18e00;color:#fff;border-radius:6px;}
    }
    .clo_mod{background:url(/img/close_mod.png) center no-repeat;position:absolute;height: 70px;width: 60px;top: 0;right: 0;cursor: pointer;}
}
.alert{transform:initial;margin-left: -200px;margin-top:-200px;z-index: 1000;min-width:400px;max-width:400px;box-shadow:0 0 10px #CCC;
    &:before{height: 50px;line-height: 50px;text-align:center;padding:0;}
    .clo_mod{height: 50px;width: 50px;}
    .mod_content{padding: 20px 20px 0 20px;color: #777;text-align:center;font-size:15px;}
}
//蒙层
#mod{position:fixed;background:rgba(0,0,0,0);top:0;bottom:0;left:0;right:0;display:none;z-index:999;
    img{position:absolute;top:50%;left:50%;margin:-25px 0 0 -25px;height:50px;width:50px;display: none;}
}


//表格公共样式
.common_table{margin-bottom:30px;
	.con_search{padding:0 0 10px;display:flex;align-items:center;margin-bottom:20px;
		.search_item{font-size:16px;color:#666;margin-right:80px;display:flex;align-items:center;justify-content: center;
			.label{}
			.input_item{width:260px;height:36px;margin:5px;margin-left:10px;outline:none;border:none;
				border: 1px solid #E8E8E8;;border-radius:4px;padding-left:10px;box-sizing:border-box;
			}
			&:nth-last-of-type(1){margin-right:0;}
		}
	}
	.table_con{background:#fff;border-radius: 6px;border: 1px solid  #DEE2E6;margin-top: 20px;
		.tab_tit{background: #F8F8F9;overflow:hidden;border-bottom:1px solid#DEE2E6;
			span{height:50px;float:left;line-height:50px;border-right:1px solid#DEE2E6;width:17%;font-weight:bold;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;text-align:center;color:#444;
				&:nth-of-type(1){width:19%;}
				&:nth-of-type(3){width:19%;}
				&.tab_check{background:url(/img/checkbox.png) center no-repeat;cursor:pointer;display:flex;align-items:center;justify-content:center;
					&:hover{background-image:url(/img/checkbox_hover.png);}
					&.active{background-image:url(/img/checkbox_active.png);}
					&.disabled{background:none;
						em{width:16px;height:16px;background:#e1e1e1;border-radius: 2px;}
					}
				}
                &:nth-last-child(1){border-right:none;width:10%;}
			}
			&.tab_cell{background:transparent;
                span{font-weight:normal;user-select: text;-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;
                	em{color:#f5483a;padding-right:20px;cursor:pointer;
                		&:nth-last-child(1){padding-right:0px;color:#f18e00;}
                	}
                }
            }
            &.tab_cell:hover{background:#F8F8F9;}
            &:nth-last-child(1){border-bottom:none;}
            &.tab_check_tit{
            	span{width:16%;
            		&.tab_check{width:5%;}
            		&:nth-of-type(2){width:19%;}
					&:nth-of-type(4){width:17%;}
            		&:nth-last-child(1){border-right:none;width:9%;}
            	}
            }
		}
		.no_data{height: 60px;display: flex;align-items: center;justify-content: center;color: #999;}
	}
	//pagination
	.tab_page{text-align:right;margin-top:20px;
        span{height:25px;width:25px;display:inline-block;border:1px solid #ddd;line-height:25px;text-align:center;color:#333;font-size:12px;cursor:pointer;margin:0 5px;border-radius:4px;
            &:hover,&.active{background:#f18e00;border-color:#f18e00;color:#fff;}
        }
        i{height:25px;width:25px;display:inline-block;background:url(/img/back_icon.png) center no-repeat;vertical-align:middle;cursor:pointer;
            &.page_bks{background-image:url(/img/backs_icon.png)}
            &.page_fd{background-image:url(/img/next_icon.png)}
            &.page_fds{background-image:url(/img/nexts_icon.png)}
        }
    }
}


//公共module
.common_module_wrap{position:fixed;background:rgba(0,0,0,0.8);top:0;left:0;width:100%;height:100%;z-index:1000;
	.module{background:#fff;border-radius:20px;padding:20px 30px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;flex-direction: column;align-items:center;justify-content: center;
		.clo_mod{position: absolute;right:20px;top:20px;z-index:100;background:url('/img/wap_index/sz_gb.png') no-repeat center;background-size:15px 15px;width:15px;height:15px;cursor: pointer;}
		.module_title{color:#333;font-size:16px;text-align:center;margin-bottom:30px;}
		.module_content{margin-bottom:30px;}
		.btn_group{display:flex;align-items:center;justify-content: space-between;width:200px;}
	}
	&.hide{display:none;}
}

//公共按钮
.common_button{
	.btn{border-radius:6px;display:inline-flex;align-items: center;justify-content: center;padding:0;cursor: pointer;}
	.block_btn{background:#f18e00;color:#fff;}
	.border_btn{border:1px solid #f18e00;color:#f18e00;background:#fff;box-sizing: border-box;}
	.default_btn{border:none;background-color: rgba(255,255,255,0);}
}

//二级页面标题
.common_sub_title{display:flex;align-items:center;color:#444;font-size:16px;padding:20px;justify-content: space-between;
	.back_icon{width:18px;height:18px;margin-right:10px;cursor: pointer;}
}

//dialogAlert
.dialog_wrap{position:fixed;background:rgba(0,0,0,0.8);top:0;bottom:0;left:0;right:0;display:none;z-index:1000;
	.dialog_alert{position: fixed;top:50%;left:50%;transform:translate(-50%,-50%);background:#fff;border-radius:10px; min-width:400px;display:flex;flex-direction: column;justify-content: center;align-items: center;
		.clo_mod{width: 50px;height: 50px;background: url(/img/close_mod.png) center no-repeat;position: absolute;cursor: pointer;top:0;right:0;}
		.title{height: 50px;line-height: 50px;text-align:center;padding:0;color: #f18e00;border-bottom: 1px solid #ddd;font-size: 18px;margin:0;font-weight:normal;width:100%;}
		.message{padding: 20px 20px 0 20px;color: #777;text-align: center;font-size: 15px;}
		.btn_group{margin: 30px 0;width:200px;display: flex;justify-content: space-between;}
	}
}

//input输入框
.common_input{
	.input{border:none;outline:none;width:240px;height:30px;border:1px solid #eaeaea;border-radius:4px;padding:0 10px;}
}

// 公共tab标签
.common_tab_box {display: flex;width:100%;border-bottom:1px solid #bbb;
	
	.tab_item{padding: 10px;margin-right: 30px;color: #333;
		
		&.active{font-weight: 900;border-bottom: 3px solid #f18e00;}
		
	}
	
}

// 上传图片公共弹框
.upload_image_module{
	&.upload_image_local{
		.module{
			.module_content{border:none;margin-bottom:0;}
		}
	}
	.module{padding:20px 0;
		.module_title{margin-bottom:20px;}
		.module_content{width:100%;margin-bottom: 20px;border-bottom: 1px solid #bbb;}
		.upload_image_container{position: relative;top: -30px;
			.common_tab_box{box-sizing: border-box;padding: 0 20px;}
				
			.tab_pane{
				.image_list{display:flex;flex-wrap: wrap;margin-top:20px;padding:0 20px;
					.image{width:100px;object-fit:contain;margin-right:20px;margin-bottom:20px;box-sizing:border-box;
						&:nth-child(5n){margin-right:0;}
					}
				}
				.upload_out_box{position: relative;width: 620px;margin-top: 20px;padding:0 20px;box-sizing: border-box;display:flex;justify-content: center;
					.upFileBox{width: 500px;position: absolute;top: 0;bottom: 0;z-index: 10;opacity: 0;}
					
					.upload_inner_box{width: 500px;height: 200px;border-radius: 4px;display: flex;align-items: center;justify-content: center;flex-direction: column;overflow:hidden;
						.image{object-fit: contain;max-height:140px;max-width:498px;}
							
						.dragbox{width: 498px;height:142px;display: flex;align-items: center;justify-content: center;margin-bottom: 20px;border: 1px dashed #bbb;border-radius: 4px;}
							
					}
				}
			}
		
			.tab_page{text-align:right;margin-top:20px;
				span{height:25px;width:25px;display:inline-block;border:1px solid #ddd;line-height:25px;text-align:center;color:#333;font-size:12px;cursor:pointer;margin:0 5px;
					&:hover,&.active{background:#f18e00;border-color:#f18e00;color:#fff;}
				}
				i{height:25px;width:25px;display:inline-block;background:url(/img/back_icon.png) center no-repeat;vertical-align:middle;cursor:pointer;
					&.page_bks{background-image:url(/img/backs_icon.png)}
					&.page_fd{background-image:url(/img/next_icon.png)}
					&.page_fds{background-image:url(/img/nexts_icon.png)}
				}
			}

			.empty_view{width:100%;text-align:center;color:#666;}
			// 角标库
			&.upload_flag_container{
				.tab_pane{
					.image_list{
						.image{width:60px;}
					}
					.upload_out_box{width: 420px;
						.upFileBox{width: 360px;}
						
						.upload_inner_box{width: 360px;
							.image{object-fit: contain;max-height:140px;max-width:298px;}
								
							.dragbox{width: 358px;}
								
						}
					}
				}
			}
		}
	}
}
