#floruit_cms{position:relative;height:100%;min-height:100%;}
.floruit_index{
    .slider_up{padding:20px 182px;overflow:hidden;box-sizing:border-box;background:url('/img/index/point_home.png') repeat-x 0 30px;
        .today_top{padding:0 100px 0 136px;position:relative;background-color:#fff;}
        .head_title{position:absolute;left:0;width:114px;top:1px;}
        .scroll_out_box{height:20px;overflow:hidden;
            .auto_scroll_box{position:relative;
                a{display:block;font-size:14px;color:#777;height:20px;line-height:20px;}
            }
        }
        .slide_do{position:absolute;right:8px;top:-5px;width:30px;height:30px;display:block;border:1px solid #e8e8e8;background:url('/img/index/slide_up.png') no-repeat center center;cursor:pointer;
            &.slide_up{right:38px;border-right:1px solid #fff;
            }
            &.slide_down{background-image:url('/img/index/slide_down.png');}
            &:hover{border-color:#f18e00;
                &.slide_up{background-image:url('/img/index/slide_up_active.png');border:1px solid #f18e00;z-index:2;}
                &.slide_down{background-image:url('/img/index/slide_down_active.png');z-index:2;}
            }
        }
        
    }
    .advertisement{overflow:hidden;height:180px;margin-bottom:20px;
        .adverti_item{margin-left:12px;float:left;border:1px solid #e8e8e8;box-sizing:border-box;width:392px;height:180px;cursor:pointer;
            &.first_item{margin-left:0;}
            img{width:100%;height:100%;}
        }
    }
    .limitActivity{height:426px;background:url('/img/index/limit_activity_bg.png') no-repeat center center;background-size:100% 100%;padding:20px 20px;box-sizing:border-box;overflow:hidden;
        .title{font-size:20px;color:#333;font-weight:bold;text-align:center;}
        .info{font-size:12px;color:#777;line-height:24px;text-align:center;}
        .activity_item{width:570px;float:left;height:316px;padding-top:50px;box-sizing:border-box;margin-top:20px;position:relative;padding-left:302px;box-shadow:0 1px 4px 1px rgba(239, 143, 5, 0.52);
            &.limit_time{margin-right:20px;background:url('/img/index/limit_time.png') no-repeat center center;background-size:100% 100%;}
            &.limit_num{background:url('/img/index/limit_time.png') no-repeat center center;background-size:100% 100%;}
            .goods_img{width:260px;height:260px;position:absolute;left:0;bottom:0;cursor:pointer;}
            .title{margin-top:60px;font-size:18px;font-weight:bold;color:#333;text-align:left;
                span{cursor:pointer;}
            }
            .info{margin-top:10px;font-size:14px;color:#999;text-align:left;}
            .price{margin-top:20px;font-size:24px;font-weight:bold;color:#f18e00;
                &:before{content:'￥';font-weight:normal;font-size:12px;}
                span{font-size:12px;}
            }
            .old_price{font-size:12px;color:#999;margin-top:4px;text-decoration: line-through;}
            .activity_title{position:absolute;top:11px;right:7px;width:90%;height:30px;line-height:30px;text-align:right;
                .activity_info{font-size:12px;color:#fff;margin-right:12px;}
                .activity_time{color:#fff;
                    b{display: inline-block;height:30px;background-color:#000;margin-right:3px;font-weight:bold;font-size:16px;box-sizing:border-box;padding: 0 5px;border-radius:5px;
                      width:20px;text-align:center;}
                    i{display:inline-block;height:30px;width:14px;background:url('/img/index/time_point.png') no-repeat center center;background-size:100% 100%;}
                }
                .activity_btn{display:inline-block;padding:0 10px;background-color:#000;height:30px;font-size:14px;color:#fff;border-radius:20px;font-weight:bold;}
            }
        }
    }
    .index_title{padding-left:50px;padding-top:20px;margin-top:30px;padding-bottom:10px;font-size:18px;color:#333;font-weight:bold;background:url('/img/index/today_recommend.png') no-repeat 0 18px;
        background-size:41px 28px;
        span{font-size:12px;color:#777;padding-left:8px;font-weight:normal;}
        .has_more{font-size:12px;color:#777;float:right;font-weight:normal;padding-right:10px;background:url('/img/index/has_more_icon.png') no-repeat right center;
            &:hover{color:#f18e00;background-image:url('/img/index/has_more_icon_active.png');}
        }
        &.today_recommend{background-image:url('/img/index/today_recommend.png');}
        &.week_great{background-image:url('/img/index/week_great.png');}
        &.online_retailers{background-image:url('/img/index/online_retailers.png');}
        &.restaturant{background-image:url('/img/index/restaturant.png');}
    }
    .index_goods{
        &:after{visibility: hidden;display: block;font-size: 0;content: " ";clear: both;height: 0;}
        .goods_item{width:232px;height:322px;box-sizing:border-box;border:1px solid #e8e8e8;float:left;margin-left:10px;cursor:pointer;margin-top:10px;position:relative;
            transition:all .2s ease-in-out 0s;
            &.belone_first{margin-left:0;}
            img{width:230px;height:230px;}
            .title{padding:0 20px;font-size:14px;color:#333;font-weight:bold;margin-top:2px;margin-bottom:0;}
            .info{padding:0 20px;font-size:12px;color:#777;margin-top:4px;}
            .price{font-size:18px;color:#f18e00;padding:0 20px;margin-top:8px;
                &:before{content:'￥';font-size:12px;}
                span{font-size:12px;}
                .old_price{color:#ccc;padding-left:10px;text-decoration: line-through;
                    &:before{content:'￥';}
                }
            }
            .add_cart{position:absolute;display:block;opacity:0;width:26px;height:24px;background:url('/img/index/cart_add.png') no-repeat center center;background-size:100% 100%;
            right:10px;bottom:18px;z-index:3;transition:all .2s ease-in-out 0s;}
            &:hover{border:1px solid #fff;box-shadow:0 0 12px -5px #000;
                .add_cart{opacity:1;}
            }
        }
    }
}

// all goods class content
.goods_class{position:absolute;width:750px;top:36px;left:0;overflow:hidden;min-height:500px;
    .type_list{width:180px;float:left;height:500px;border-left:1px solid #e8e8e8;border-bottom:1px solid #e8e8e8;box-sizing:border-box;background-color:rgba(255,255,255,.95);
        li{display:block;box-sizing:border-box;padding:10px 0 0 50px;border-right:1px solid #f18e00;width:180px;height:57px;overflow:hidden;position:relative;
            &.active,&:hover{border:1px solid #f18e00;background-color:#fff;border-right:1px solid #fff;}
            img{position:absolute;left:16px;top:10px;width:20px;height:20px;}
            &.more_class{
                &:hover{border:none;background-color:transparent;border-right:1px solid #f18e00;}
                a{font-size:12px;color:#333;}
            }
        }
        span{display:block;}
        .type_name{
            a{font-weight:bold;color:#333;font-size:14px;}
        }
        .hot_class{font-size:12px;color:#777;margin-top:6px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
            a{margin-right:10px;font-size:12px;
                &:hover{color:#f18e00;}
            }
        }
    }
    .class_detail{width:570px;border:1px solid #f18e00;float:left;box-sizing:border-box;padding:25px 0;padding-right:25px;border-top:none;min-height:500px;
        background-color:rgba(255,255,255,.95);
        .type_sec_list{position:relative;padding-left:100px;min-height:35px;
            li{display:inline-block;padding:0 10px;line-height:15px;height:15px;border-left:1px solid #777;margin-bottom:15px;
                &.type_name{position:absolute;left:20px;top:5px;width:80px;text-align:right;box-sizing:border-box;padding-right:10px;padding-left:0;font-size:12px;font-weight:bold;
                    color:#f18e00;border:none;background:url('/img/index/sub_arrow_rigt.png') no-repeat right center;background-size:6px 6px;
                    a{font-size:12px;color:#f18e00;}    
                }
                &.first_item{border:none;}
            }
        }
        .class_item{font-size:12px;color:#777;display:block;
            &:hover{color:#f18e00;}
        }
    }
}
//
.guide_search{overflow:hidden;padding-bottom:10px;
    .empty_guide_item{width:50%;float:left;box-sizing:border-box;
        &.history_search{padding-right:10px;border-right:1px solid #e8e8e8;
            span{}
        }
        &.hot_search{padding:0 10px;border-left:1px solid #e8e8e8;margin-left:-1px;}
        h4{font-size:12px;color:#777;padding:5px 0;margin-bottom:5px;
            b{color:#555;font-weight:normal;font-size:12px;float:right;cursor:pointer;
                &:after{content:'清除'}
                &:hover{color:#f18e00;}
            }
        }
        span{font-size:12px;color:#999;display:inline-block;padding:2px 5px;border:1px solid #e8e8e8;border-radius:4px;margin-right:6px;margin-top:5px;cursor:pointer;
            &:hover{color:#f18e00;}
        }
    }
}
.my_floruit_box{float:left;width:130px;height:45px;line-height:45px;box-sizing:border-box;margin-right:28px;
    position:relative;
    .content_button{font-size:14px;color:#777;padding-left:30px;padding-right:20px;height:100%;text-align:center;overflow:hidden;position:relative;cursor:pointer;z-index:701;
        background-color:#f5f5f5;border:1px solid #e8e8e8;box-sizing:border-box;
        &:before,&:after{content:'';display:block;width:17px;height:17px;background:url('/img/index/my.png') no-repeat center center;background-size:100% 100%;position:absolute;
        left:10px;top:14px;}
        &:after{background-image:url('/img/index/my_arrow.png');background-size:10px 5px;right:5px;left:auto;}
    }
    .my_floruit_info{overflow:hidden;width:270px;position:absolute;left:-140px;box-sizing:border-box;top:44px;z-index:700;line-height: initial;
        height:0;
        border:1px solid transparent;transition:height .1s ease-in-out 0s;
        .container_box{
            //padding:20px 20px;
            padding:0 20px;
        }
        .info_title{padding:0 20px 20px;font-weight:bold;color:#333;border-bottom:1px solid #e8e8e8;display:none;}
        .info_item_box{
            li{display:inline-block;width:113px;box-sizing:border-box;padding:10px 20px 0;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
                a{font-size:12px;color:777;
                    &:after{content:'>';margin-left:3px;font-size:14px;}
                    &:hover{color:#f18e00;}
                }
                &.noneAfter{
                    a{&:after{content:''}}
                }
            }
        }
    }
    &:hover{
        .content_button{background-color:#fff;border-bottom:1px solid #fff;}
        .my_floruit_info{background-color:#fff;
            //height:154px;
            height:80px;
            border:1px solid #e8e8e8;}
    }
}

// 轮播
.floruit_slider{position:relative;height:540px;
    .slider_body{width:100%;overflow:hidden;position:relative;height:500px;
        .slider_main{width:30000px;overflow:hidden;position:relative;
            li{height:500px;float:left;cursor:pointer;}
            img{width:100%;height:500px;}
        }
    }
    .slider_footer{width:1200px;position:absolute;bottom:-1px;left:50%;margin-left:-600px;z-index:1;opacity:.5;
        li{height:40px;width:295px;background-color:#000;float:left;margin-left:5px;cursor:pointer;
            &:first-child{margin-left:0;}
            &.active{background-color:#f18e00;}
        }
    }
}

.floruit_index{
    &>div{position:relative;}
    .ctrModule{display:block;width:100%;height:100%;position:absolute;left:0;top:0;background-color:rgba(0,0,0,.4);
        &.activeModule{background-color:transparent;
            &.hideModule{
                &:before{color:#f18e00;}
            }
        }
        &.hideModule{text-align:center;
            &:before{content:'已隐藏';font-size:60px;color:#fff;display:inline-block;height:72px;width:250px;position:absolute;left:50%;top:50%;margin-top:-42px;margin-left:-125px;}
        }
    }
}

// login

body{margin:0;padding:0;background:#f7f7f7;color:#1c1b1a;-webkit-text-size-adjust:none;font-family: "微软雅黑";}
ul,ol,li,form{margin:0;padding:0;}ul,ol,li{list-style:none}
img{border:0}em,i,span{font-style:normal}
h1,h2,h3,h4,div,li,p{margin:0;padding:0;font-weight:normal;-webkit-tap-highlight-color:transparent;}
.layout{background: #fff;font-weight: bold;height: 100%;width: 100%;}
header{margin-left: 80px;height: 100px;padding-top: 30px;}header img{margin-right:20px;float: left;}
header span{font-size: 30px; color:#999;font-family: "微软雅黑"; line-height: 60px; height: 110px;display: block; float: left;justify-content:center;}
section{background:url("../img/bg.png") no-repeat 0 0; width: 100%;height: 615px;}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #efefef inset
}
input, textarea {
 -webkit-tap-highlight-color: transparent;
 margin: 0;
 padding: 0;
 font-family: Helvetica, "Microsoft YaHei","Heiti","Heiti SC", "DroidSans", "DroidSansFallback","Helvetica Neue",  "STHeiTi", Arial, sans-serif;
 -webkit-appearance: none; }
 input:focus, select:focus, textarea:focus {
  outline: none; }
  ::-webkit-scrollbar{width: 6px;background: transparent;}
::-webkit-scrollbar-thumb{background:rgba(0,0,0,.3);border-radius: 100px}
input{background-color: none}
.logindiv{margin-top:130px;margin-right: 180px;float: right;width: 420px;height:380px;background-color:#fff;font-family: "微软雅黑";border-radius:15px;-webkit-box-shadow:0 0 10px #CCC;-moz-box-shadow:0 0 10px #CCC;box-shadow:0 0 10px #CCC;}
.loginw{padding: 30px 60px;}
.loginw h1{color: #f18e00;font-size:22px;font-weight: bold;line-height: 60px;height: 60px;width: 100%;text-align: center;margin-bottom: 20px;}
.loginw .inputLab{height: 44px;padding: 2px;font-size: 14px;border:solid 1px #ccc;border-radius:10px;margin-bottom: 28px;background-color: #efefef;}
.loginw .inputLab label{float: left;width: 20px;margin: 10px;}
.labelName{background:url("../img/data.png") no-repeat 0 0; width: 20px;height: 24px;}
.labelPassword{background:url("../img/account.png") no-repeat 0 0; width: 20px;height: 24px;}
.loginw .inputLab input{width:200px;height: 40px;line-height: 40px;border:none;outline: none;background-color: #efefef;font-family: "微软雅黑";}
.J_Submit{width: 300px; height: 46px; line-height: 46px; font-size: 18px; background-color: #f18e00; border: none; color: #fff;border-radius:10px; cursor: pointer;}
footer{height: 200px;width:100%; text-align: center;}
footer .ft-info{width:100%; text-align: center; height: 20px; line-height: 20px; margin-top: 20px;font-size: 12px; color: #222;}
footer .ft-info a{color:#222; text-decoration: none;}
footer p{font-size: 12px; color: #222;}

//live
.live_container{padding-left:40px;}
#live_content{
    .live_module_content{margin-top:30px;
        .module_title{border-left:5px solid #f18e00;padding-left:10px;color:#333;font-weight:bold;font-size:16px;}
        .module_list{margin-top:30px;}

        .live_item{width:300px;border:1px solid #e8e8e8;border-radius:6px;padding:15px 15px;float:left;margin-left:20px;margin-bottom:20px;height:122px;position:relative;
            &.add_new{position:relative;cursor:pointer;
                &::before,&::after{content:'';width:70px;height:9px;border-radius:9px;background-color:#e8e8e8;display: block;position:absolute;left:135px;top:70px;}
                &::before{transform: rotate(90deg);}
            }
            .bg_imge{position:absolute;width: 100%;height:100%;z-index:0;left:0;top:0;border-radius:6px;}
            &:first-child{margin-left:0;}
            &:nth-child(4n+1){margin-left:0;}
            .title{padding-right:50px;margin:0;font-weight:bold;color:#fff;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;position:relative;
                .right_do{width:50px;position:absolute;right:0;top:0;text-align:right;
                    .icon{cursor:pointer;display:inline-block;width:20px;height:20px;background:url('/img/do_some.png') no-repeat center center;background-size:80px;background-position: 3px center;}
                    .del{background-position: -64px center;margin-left:10px;}
                }
            }
            .name{color:#fff;font-size:14px;margin-top:8px;position:relative;z-index:3;}
            .goods_imgage_list{width:100%;padding:5px 0;margin-top:10px;overflow:hidden;position:relative;z-index:3;
                li{width:60px;float:left;margin-left:16px;height:60px;
                    &:first-child{margin-left:0;}
                    img{width:100%;max-height:100%;height:auto;border:1px solid #e8e8e8;}
                    &.more_goods{
                        span{display: block;width:8px;height:8px;border-radius: 50%;background-color: #e8e8e8;float:left;margin-top:27px;margin-left:6px;
                            &:first-child{margin-left:13px;}
                        }
                    }
                }
            }

            .start_play_new{position:absolute;width:50px;height:50px;background:url('/img/live/cms_play.png') no-repeat center center;background-size: 50% auto;top: 100px;
                z-index: 995;right: 0;cursor: pointer;}

            &.playor_item{position:relative;
                .title{padding-right:95px;
                    .live_statu{padding-left:5px;padding-right: 30px;background-color: rgba(0,0,0,.2);border-radius: 6px;color:#fff;font-weight: normal;font-size: 14px;}
                    .right_do{width:90px;
                        .down{background-position: -21px center;}
                        .up{background-position: -42px center;margin-left:10px;}
                    }
                }
                .name{padding-right:70px;position:relative;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#fff;
                    .right_view{position: absolute;right:0;top:0;padding-right:20px;}
                }
                .goods_imgage_list{margin-top:35px;
                    li{height:40px;width:40px;}
                }
                .bg_img{position:absolute;width:100%;height:100%;left:0;top:0;z-index:0;}
            }
        }
    }
}

.fixed_container{padding-top:113px;
    .top_container{position: fixed;top:0;width:100%;padding-left:200px;left:0;z-index:2;background-color:#fff;}
    .left_container{position: fixed !important;z-index:3;overflow: auto;bottom: 0;min-height: auto!important;}
}

.module_fixed_content{position:fixed;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);z-index:999;
    .module_content{width:400px;padding:20px 15px;position:absolute;left:50%;top:50%;transform: translate(-50%,-50%);background-color:#fff;border-radius:7px;max-height:100%;
        .close{position: absolute;right:-10px;top:-10px;width:30px;height:30px;background:url('/img/close.png') no-repeat center center;
        cursor:pointer;background-size: 100% auto;}
        .moduleFileImage{padding-left: 200px;position: relative;min-height: 100px;
            .upFileBoxinput{position: absolute;left: 0;top: 0;width: 200px;height: 100px;border-radius: 5px;width: 180px;opacity: 0;z-index:3;cursor:pointer;}
            .live_img{position: absolute;left: 0;top: 0;width: 180px;height: 100px;z-index:2;border-radius: 5px;}
            h5{margin:0;color:#777;padding-bottom: 10px;}
            p {font-size: 12px;color: #777;line-height: 20px;}
            .addBoxBtn{position:absolute;width:180px;height:100px;left:0;top:0;border:1px #e8e8e8 dotted;
                &::before,&::after{content:'';width:70px;height:9px;border-radius:9px;background-color:#e8e8e8;display: block;position:absolute;left:55px;top:47px;}
                &::before{transform: rotate(90deg);}
            }
        }
        .inputBox{margin-top:20px;overflow:hidden;
            label{color:#555;font-size:14px;text-align:center;padding:0 10px;float:left;padding-top:5px;width:90px;}
            .input_item{border:none;background-color:#fafafa;padding:8px 10px;width:240px;border-radius: 6px;float:left;min-height: 31px;box-sizing: border-box;
                &.image_box{height:auto;overflow: hidden;padding-left:0;width:290px;
                    li{float: left;width:60px;height:60px;margin-left:10px;position:relative;margin-bottom:10px;
                        &:nth-child(4n+1){margin-left:0;}
                        &:first-child{margin-left: 0;}
                        img{width:100%;max-height:100%;}
                        .del{position: absolute;right:-10px;top:-10px;width:20px;height:20px;background:url('/img/close.png') no-repeat center center;cursor:pointer;background-size: 100% auto;z-index:3;}
                    }
                }
                &.isSelect{padding-right:25px;background:url('/img/index/my_arrow.png') no-repeat 220px center;background-color:#fafafa;}
                span.radio{background:url('/img/live/radio.png') no-repeat 3px 6px;background-size:60px;float:left;width:30px;height:20px;margin-right:20px;padding-left:20px;cursor:pointer;}
                span.radio.active{background-position: -44px 6px;}
            }
        }
        .bottom_sure{text-align:center;margin-top:20px;
            span{display: inline-block;width:80px;background-color:#f18e00;padding:6px 10px;color:#fff;font-size:14px;border-radius: 6px;cursor: pointer;}
        }
    }
}

.liveSelectShow{position:fixed;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);z-index:999;}

.liveSelectShow .liveSelectContent{width:500px;padding:20px 15px;position:absolute;left:50%;top:50%;transform: translate(-50%,-50%);background-color:#fff;border-radius:7px;

}
.liveSelectShow .liveSelectContent .close{position: absolute;right:-10px;top:-10px;width:30px;height:30px;background:url('/img/close.png') no-repeat center center;
cursor:pointer;background-size: 100% auto;}
.liveSelectContent h4{color:#f18e00;font-size:14px;font-weight: bold;}
.liveSelectContent .table_box{height:300px;margin-top:20px;overflow-y: auto;border:1px solid #e8e8e8;}
.table_box ul{overflow: hidden;border-bottom: 1px solid #e8e8e8;cursor:pointer;}
.table_box ul:hover li{color:#f18e00;}
//.table_box ul:hover li.radio{background-position: -36px center;}
.table_box ul.header{background-color:#f5f5f5;}
.table_box ul.header:hover li{color:#555;}
.table_box ul.header li.title{text-align:center;}
.table_box ul li{float: left;width:40px;text-align:center;padding:0px 5px;box-sizing: border-box;height:34px;line-height: 34px;border-right:1px solid #e8e8e8;font-size:14px;color:#555;}
.table_box ul li:last-child{border-right:none;}
.table_box ul li.date{width:110px;}
.table_box ul li.name{width:100px;}
.table_box ul li.title{width:240px;text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.table_box ul li.radio{background:url('/img/live/radio.png') no-repeat 12px center;background-size:60px;}
.table_box ul li.radio.active{background-position: -36px center;}

.liveSelectContent .sure_botton{text-align: center;margin-top:30px;}
.liveSelectContent .sure_botton span{cursor:pointer;display: inline-block;width:100px;text-align:center;margin:0 auto;background-color: #f18e00;color:#fff;border-radius: 6px;padding:5px 20px;}

.liveSelectContent .search_content_live{margin-top:20px;
    span,input,select{float:left;box-sizing: border-box;height:27px;}
    span{font-size:14px;color:#555;padding-right:10px;line-height: 27px;
        &.is_bettow{padding-left:10px;}
        &.is_end{padding-left:30px;}
    }
    input{border:1px solid #e8e8e8;padding:5px 5px;width:100px;padding-right:20px;background:url('/img/live/date_icon.png') no-repeat 78px center;background-size:15px auto;}
    select{width:133px;padding-left:10px;border:1px solid #e8e8e8;padding-right:20px;background:url('/img/index/my_arrow.png') no-repeat 110px center;}
}
//fade
#fade{pointer-events:none;background:rgba(0,0,0,.6);color:#fff;position:fixed;bottom:50%;left:0;right:0;width:250px;margin:0 auto;border-radius:10px;padding:20px;text-align:center;font-size:14px;opacity:0;z-index:9999999;}

#mod{position:fixed;top:50%;left:50%;margin-left:-50px;margin-top:-50px;display: none;}

.empty_box{height:180px;text-align:center;
    &.module_empty{padding-top:50px;}
}

//整点秒杀
#miaoshaLeft{margin-top:20px;height:240px;
    .content_left{width:150px;height:220px;background:#f18e00;color:#fff;text-align:center;padding-top:20px;float:left;
        .title{font-size:24px;margin-bottom:10px;}
        .sub_title{font-size:18px;}
        img{width:21px;height:24px;margin:20px auto 20px;}
        .acitvity_state{
            .text{font-size:18px;margin-bottom:10px;}
            .least_time{margin:0 auto;font-size:20px;
                span{display:inline-block;padding:6px 5px;background:#fff;color:#f18e00;border-radius:4px;}
                em{margin:0 4px;font-style:normal;display:inline-block;}
            }
        }
    }
    .content_right{float:left;padding:0px 40px;padding-left:0;
        .img_box{width:240px;height:240px;margin-right:40px;float:left;display:flex;align-items:center;
            img{width:100%;}
        }
        .detail{position:relative;float:left;margin-top:30px;
            .title{font-size:20px;font-weight:900;color:#333;margin-bottom:30px;width:400px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
            .sub_title{font-size:18px;color:#666;margin-bottom:40px;width:400px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
            .progress_bar{margin-bottom:20px;
                .bar{width:300px;height:20px;border:1px solid #f18e00;margin-right:20px;float:left;
                    .bar_inner{width:150px;height:18px;background:#f18e00;}
                }
                .bar_text{float:left;color:#666;font-size:16px;}
            }
            .price_box{color:#f18e00;
                .active_price{font-size:22px;display:block;
                    b{font-size:14px;font-weight:normal}
                }
                .origin_price{display:block;color:#666;font-size:18px;text-decoration:line-through;}
            }
            .active_btn{position:absolute;bottom:0;right:-100px;width:100px;height:40px;line-height:40px;text-align:center;color:#fff;font-size:18px;background:#f18e00;border-radius:6px;
                &.gray_btn{background:#ccc;color:#fff;}
            }
        }
    }
    
}
#miaoSha{
    .toggleTab{margin-bottom:10px;
        .tab{display:block;float:left;padding:5px 10px;border-radius:4px;background:#ccc;color:#fff;font-size:14px;cursor:pointer;
            &:nth-of-type(2){margin:0 20px;}
            &.activeTab{border:1px solid #f18e00;color:#f18e00;background:#fff;}
        }
    }
    .miaoShaContainer{border:1px solid #f18e00;padding:20px;border-radius:4px;margin-bottom:20px;
        .commonTitle{color:#333;font-size:16px;margin-bottom:20px;}
        .withTitleInput{padding-left:20px;margin-bottom:32px;padding:0;margin-top:0;height:30px;line-height:30px;position:relative;padding-left:95px;
            label{position:absolute;left:20px;top:0;width:80px;margin-right:10px;display:inline-block;font-size:14px;color:#333;margin-right:10px;}
            &.setTimeContainer{
                .setTimebox{width: 100px;height: 30px;line-height: 30px;float: left;background-color: #f0f0f0;border: 1px solid #ddd;font-size: 12px;color: #333;padding: 0 20px;padding-top: 3px;background: url(../img/icon_down_up.png) center no-repeat;background-size: 9px 11px;background-position: 120px center;
                    &.leftDate{border-top-left-radius: 5px;border-bottom-left-radius: 5px;}
                    &.rightTime{border-left: none;border-top-right-radius: 5px;border-bottom-right-radius: 5px;}
                }
                .tip{font-size:14px;color:#666;padding-left:10px;}
            }
            .langInp{width:285px;}
            .priceInp{width:120px;}
            .small_title{font-size: 14px;color: #333;padding-left: 28px;margin-right: 18px;}
        }
        .clear_btn{border-radius:4px;color:#fff;background:#f7c976;text-align:center;font-size:16px;margin:0 auto;float:none;margin-top:20px;}
    }
}
.clearfix:after{visibility:hidden;display:block;font-size:0;content:'';clear:both;height:0;}